import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	BookmarkNoEdit,
	BookmarkEditEmit,
	BookmarkReturnBook,
	BookmarkBook,
	TileNullClick,
	BookmarkBasket,
} from '../../api'

import { allBookmarks } from '../../store/bookmarks'

import './index.scss'

function SettingsBookmark(props) {
	const { cat, tip, id, show = true } = props

	const all_bookmarks = useSelector(allBookmarks)

	const [noEdit, setNoEdit] = useState()

	useEffect(() => {
		BookmarkNoEdit.emit()

		BookmarkNoEdit.on(data => {
			setNoEdit(!!data.filter(i => i === id).length)
		})

		return () => {
			BookmarkNoEdit.off()
		}
	}, [id])

	// Не показывать блок кнопок
	if(!show) return false

	const editClick = (id) => {
		BookmarkEditEmit.emit(id)
	}

	const returnBoxClick = (id) => {
		BookmarkReturnBook.emit(id)
	}

	const boxClick = (id) => {
		BookmarkBook.emit(id)
	}

	const nullClick = (id) => {
		TileNullClick.tileClickNull(id, all_bookmarks)
	}

	const basketClick = (id) => {
		BookmarkBasket.emit(id)
	}

	return (<div className='settings-bookmark'>
		{noEdit ? null : <div className='edit' onClick={() => editClick(id)} />}
		{tip === 'ibox' ? <div className='box rotate' onClick={() => returnBoxClick(id)} /> : <div className='box' onClick={() => boxClick(id)} />}
		{cat === 'last' ? <div className='null' onClick={() => nullClick(id)} /> : null}
		{(tip === 'ibox' || tip === 'all') ? <div className="basket" onClick={() => basketClick(id)} /> : null}
	</div>)
}

export default SettingsBookmark