import { createSlice } from '@reduxjs/toolkit'

export const Socket = createSlice({
	name: "weather",
	initialState: {
		city: null, // "Москва"
		country: null, // "Россия"
		flag: null, // "Russia"
		latitude: null, // "55.737897"
		longitude: null, // "37.700720"
		weather: null,
		error: false
	},
	reducers: {
		setWeather: (state, weather) => {
			const obj = weather.payload

			state.city = obj.city || null
			state.country = obj.country || null
			state.flag = obj.flag || null
			state.latitude = obj.latitude || null
			state.longitude = obj.longitude || null
			state.weather = obj.pogoda ? JSON.parse(obj.pogoda) : null
			state.error = !!obj.error
		},
	}
})

export const {
	setWeather,
} = Socket.actions

export const dataWeather = (state) => {
	return {
		city: state.weather.city,
		country: state.weather.country,
		flag: state.weather.flag,
		latitude: state.weather.latitude,
		longitude: state.weather.longitude,
		weather: state.weather.weather
	}
}

export default Socket.reducer


