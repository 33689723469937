import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './index.scss'

import InfoCalendar from './calendar'
import InfoWeather from './info-weather'
import InfoTvBind from './info-tv-bind'
import InfoTv from './info-tv'
import InfoLenses from './indo-lenses'
import ClipboardComponent from './сlipboard'
import GeoMap from './geo-map'
import { Ready } from '../../api'
import { PrintHeader } from '../date'

import { activeCategory } from '../../store/bookmarks'

function InfoList(props) {
	const { list } = props

	const category = useSelector(activeCategory)

	const [weather, setWeather] = useState(false)
	const [tvBind, setTvBind] = useState(false)
	const [tv, setTv] = useState(false)
	const [lenses, setLenses] = useState(false)
	const [clipboard, setClipboard] = useState(false)
	const [calendar, setCalendar] = useState(false)
	const [geo, setGeo] = useState(false)

	useEffect(() => {
		Ready.ready()

		Ready.on(data => {
			console.log(data)
		})

		return () => {
			Ready.off()
		}
	}, [])

	useEffect(() => {
		const arr = !!list ? list.length ? list.split(',') : [] : []

		// Погода
		setWeather(arr.includes('1'))

		// Что? Где? Когда?
		setTvBind(arr.includes('2'))

		// TB Программа
		setTv(arr.includes('3'))

		// Линзы
		setLenses(arr.includes('4'))

		// Буфер обмена
		setClipboard(arr.includes('5'))

		// Календарь
		setCalendar(arr.includes('6'))

		// GEO
		setGeo(arr.includes('7'))

	}, [list])

	if(category !== 'last') return false

	return (<div className='info-list'>

		<div>
			<ClipboardComponent turnOn={clipboard} />
		</div>

		{calendar || weather || geo || tvBind || tv || lenses ? <div>
			<PrintHeader text='Информация' />
		</div> : ''}

		<div>

			<InfoCalendar turnOn={calendar} />

			<InfoLenses turnOn={lenses} />

			<GeoMap turnOn={geo} />

			<InfoWeather turnOn={weather} />

			<InfoTvBind turnOn={tvBind} />

			<InfoTv turnOn={tv} />

		</div>

	</div>)
}

export default InfoList