import './index.scss'

function Loader() {

	return (<div className='loader-box'>
		<div>

			<div className="loader">
				<div className="l_main">
					<div className="l_square"><span/><span/><span/></div>
					<div className="l_square"><span/><span/><span/></div>
					<div className="l_square"><span/><span/><span/></div>
					<div className="l_square"><span/><span/><span/></div>
				</div>
			</div>

		</div>
	</div>)
}

export default Loader