import { createSlice } from '@reduxjs/toolkit'

export const Server = createSlice({
	name: "server",
	initialState: {
		// a
		mhz: null,
		cpu: null,
		// b
		free: null,
		available: null,
		// c
		temp: null,
		status: null,
		// d
		restart: null,
		memory: null,
		node_cpu: null,
		// i
		balance: null,
		// f
		ssh: null,
		// Производственный календарь
		holidays: {
			year: [],
			day: []
		},
		// Автофон
		my_auto: {
			date: null,
			json: null
		}
	},
	reducers: {
		setInfoA: (state, info) => {
			const json = JSON.parse(info.payload)
			state.mhz = json.mhz
			state.cpu = json.cpu
		},
		setInfoB: (state, info) => {
			const json = JSON.parse(info.payload)
			state.free = json.free
			state.available = json.available
		},
		setInfoC: (state, info) => {
			const json = JSON.parse(info.payload)
			state.temp = json.temp
			state.status = json.status
		},
		setInfoD: (state, info) => {
			const json = JSON.parse(info.payload)
			state.restart = json.temp
			state.memory = json.memory
			state.node_cpu = json.cpu
		},
		setInfoI: (state, info) => {
			const json = JSON.parse(info.payload)
			state.balance = json.balance
		},
		setInfoF: (state, info) => {
			const json = JSON.parse(info.payload)
			state.ssh = json.status
		},
		setInfoAuto: (state, auto) => {
			const json = auto.payload
			state.my_auto = {
				date: json.date,
				json: json.json
			}
		},
		setHolidaysArray: (state, holidays) => {
			const d = holidays.payload
			state.holidays = {
				year: 'year' in d ? d.year : [],
				day:  'day' in d ? d.day : []
			}
		},
	}
})

export const { setInfoA, setInfoB, setInfoC, setInfoD, setInfoI, setInfoF, setInfoAuto, setHolidaysArray } = Server.actions

export const infoA = (state) => {
	return {
		mhz: state.server.mhz,
		cpu: state.server.cpu
	}
}
export const infoB = (state) => {
	return {
		free: state.server.free,
		available: state.server.available
	}
}
export const infoC = (state) => {
	return {
		temp: state.server.temp,
		status: state.server.status
	}
}
export const infoD = (state) => {
	return {
		restart: state.server.restart,
		memory: state.server.memory,
		cpu: state.server.node_cpu
	}
}
export const infoI = (state) => state.server.balance
export const infoF = (state) => state.server.ssh
export const infoAuto = (state) => state.server.my_auto.json

export const holidaysArray = (state) => state.server.holidays

export default Server.reducer
