import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { ioPageLogin, sendLogin } from '../../api'

import './index.scss'
import Message from '../../components/message'
import Footer from '../../components/footer'
import UiInput from '../../ui/input'
import UiButton from '../../ui/button'

function Registration() {
	const [values, setValues] = useState('')

	const handleChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		setValues(values => ({...values, [name]: value}))
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		sendLogin.loginReg(values.user, values.password,'dqr')
	}

	return (<>
		<Helmet>
			<title>Закладки</title>
			<meta name="description" content="Закладки / Избранное" />
		</Helmet>

		<div className='page-login'>

			<div className='page-login-box'>

				<form method="post" name="formLogin" onSubmit={(e) => handleSubmit(e)}>

					<h1>Регистрация</h1>

					<Message />

					<label>
						<UiInput
							type="text"
							name="user"
							value={values.user || ''}
							handleChange={handleChange}
						/>
					</label>

					<label>
						<UiInput
							type="password"
							name="password"
							value={values.password || ''}
							handleChange={handleChange}
						/>
					</label>

					<UiButton
						type='submit'
						text='Зарегистрироваться'
					/>

					<div>
						<span onClick={() => ioPageLogin.emit()}>Авторизация</span>
					</div>

				</form>

			</div>

			<Footer />

		</div>

	</>)
}

export default Registration