import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BookmarkSearch, BookmarkSearchValue } from '../../api'
import {
	setOpenNav,
	quickSearch,
	searchLenta,
	searchBookmarks,
	setSearch,
	setSearchColor,
	setBookmarkSearch
} from '../../store/bookmarks'

import './index.scss'

import UiInput from '../../ui/input'
import Logo from '../../components/logo'

function LentaSearch() {
	const lenta = useSelector(searchLenta)

	const lentaAearch = (color) => {
		BookmarkSearch.search('', color)
	}

	if(!lenta) return null

	return (<>
		{lenta.yellow ? <span onClick={() => lentaAearch('yellow')} className='search-lenta search-lenta-yellow'><i/></span> : null}
		{lenta.green ? <span onClick={() => lentaAearch('green')} className='search-lenta search-lenta-green'><i/></span> : null}
		{lenta.blue ? <span onClick={() => lentaAearch('blue')} className='search-lenta search-lenta-blue'><i/></span> : null}
		{lenta.red ? <span onClick={() => lentaAearch('red')} className='search-lenta search-lenta-red'><i/></span> : null}
	</>)
}

function QuickSearch(props) {
	const { searchValue } = props
	const dispatch = useDispatch()
	const quick_search = useSelector(quickSearch)

	const [list, setList] = useState(quick_search)

	useEffect(() => {
		setList(quick_search)
	}, [quick_search])

	const searchQuick = (el) => {
		const val = el.split(':')[0]
		searchValue(val)
		dispatch(setBookmarkSearch(val))
		BookmarkSearch.search(val, 'normal')
	}

	//if(!list) return null

	return (<div>
		<LentaSearch />
		{!!list ? list.map((el, i) => <span
			onClick={() => searchQuick(el)}
			key={i}
		>
			{el.split(':')[1]}
		</span>) : ''}
	</div>)
}


function Search() {
	const dispatch = useDispatch()
	const search = useSelector(searchBookmarks)

	const [values, setValues] = useState(search)

	useEffect(() => {
		BookmarkSearchValue.emit()

		BookmarkSearchValue.on(data => {
			dispatch(setSearch(data))

			const json = JSON.parse(data)
			const value = json.value || ''
			setValues(value)
		})

		return () => {
			BookmarkSearchValue.off()
		}
	}, [dispatch])

	const handleChange = (event) => {
		const target = event.target
		const value = target.value
		setValues(value)
		dispatch(setBookmarkSearch(value))
		dispatch(setSearchColor(''))
		BookmarkSearch.search(value)
	}

	const searchValue = (value) => setValues(value)

	const navSwitch = () => dispatch(setOpenNav())

	return (<div className='search'>
		<div>

			<div>
				<Logo />
			</div>
			<div>
				<div>
					<UiInput
						skin='search'
						type="text"
						name='search'
						value={values}
						handleChange={handleChange}
					/>
				</div>

				<div>
					<QuickSearch searchValue={searchValue} />
				</div>

			</div>
			<div>
				<span onClick={() => navSwitch()}><i /></span>
			</div>
		</div>

	</div>)
}

export default Search