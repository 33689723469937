import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './index.scss'

import { formatDate, arrWeekDays, arrMonths, fullYear, categoryName } from '../../config'
import { activeCategory, searchBookmarks } from '../../store/bookmarks'

export function PrintHeader(props) {
	const { text } = props

	return (<div className='date-separator date-separator-info'>
		<div>
			<h3>
				&nbsp;{text}&nbsp;
			</h3>
		</div>
	</div>)
}

function PrintDate(props) {
	const { week, day, month, year, text, category } = props
	const active_category = useSelector(activeCategory)
	const search_bookmarks = useSelector(searchBookmarks)

	const [categoryInfo, setCategoryInfo] = useState(category)

	useEffect(() => {
		setCategoryInfo(search_bookmarks || active_category)
	}, [active_category, search_bookmarks])

	if(categoryInfo === 'last' && day === 0 && !text) return null

	// Центр Цифры
	if(categoryInfo === 'digital') return (<div className='date-separator'>
		<div className='date-separator-logo-cc'/>
	</div>)

	// Алеан
	if(categoryInfo === 'alean' || search_bookmarks === '#alean' || search_bookmarks === '#alean_local' || search_bookmarks === '#alean_git') return (<div className='date-separator'>
		<div className='date-separator-logo-alean'/>
	</div>)

	return (<div className='date-separator'>
		<div>
			<h3>
				{categoryInfo !== 'last' ? <>{categoryName(categoryInfo, <b>Поиск: </b>)}</> : day === 0 ? <>{text}</> : <>{week}, {day} {month} {year}</>}
			</h3>
		</div>
	</div>)
}

function DateSeparator(props) {
	const { openingTime, nextOpeningTime, serialNumber, position, empty } = props
	const active_category = useSelector(activeCategory)

	const [category, setCategory] = useState(null)
	const [currentDate, setCurrentDate] = useState(null)
	const [nextDate, setNextDate] = useState(null)

	useEffect(() => {
		if (openingTime) {
			const dateCurrent = formatDate(openingTime)
			const dateNext = formatDate(nextOpeningTime)

			setCategory(active_category)
			setCurrentDate(dateCurrent.year < fullYear - 5 ? {day: 0, year: dateCurrent.year} : dateCurrent) // Дата текущий закладки
			setNextDate(dateNext.year < fullYear - 5 ? {day: 0, year: dateNext.year} : dateNext) // Дата следующий закладки
		}

	}, [openingTime, nextOpeningTime, active_category])

	if(empty) return <><PrintHeader text={''}/></>

	if(!currentDate && !nextDate && serialNumber !== 0) return null

	const showYear = nextDate ? fullYear !== nextDate.year ? `${nextDate.year} год` : '' : ''

	if((serialNumber === 0 && position === 'top') || (position === 'bottom' && category === 'last' && currentDate.day !== nextDate.day)) return <>
		<PrintDate
		week={nextDate ? arrWeekDays[nextDate.week] : ''}
		day={nextDate ? nextDate.day : 0}
		month={nextDate ? arrMonths[nextDate.month] : ''}
		year={showYear}
		category={category}
		text={nextDate ? nextDate.day === 0 && nextDate.year ? 'Что-то очень старое' : null : null}
	/></>

	return null
}

export default DateSeparator