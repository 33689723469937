import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './index.scss'

import {
	openNav,
	menuBookmark,
	setOpenNav,
	activeCategory,
	setBookmarksAll,
	bookmarksAll,
	basketAll,
	setBasketAll, setBookmarkSearch
} from '../../store/bookmarks'
import { setUsers, allUsers } from '../../store/socket'
import { setSettingsEdit } from '../../store/authorization'
import { CategoryChange, socketUsers, socketUsersEmit, ioLogout, BookmarkEditEmit, BookmarksAll, BasketAll } from '../../api'
import {copyright, declination, rect_version} from '../../config'

function Navigation() {
	const nav = useSelector(openNav)
	const menu = useSelector(menuBookmark)
	const active = useSelector(activeCategory)
	const users = useSelector(allUsers)
	const all = useSelector(bookmarksAll)
	const basket_all = useSelector(basketAll)
	const dispatch = useDispatch()

	const [show, setShow] = useState(false)

	useEffect(() => {
		socketUsersEmit.emit()

		socketUsers.on(data => {
			dispatch(setUsers(data))
		})

		BookmarksAll.on(all => {
			dispatch(setBookmarksAll(all))
		})

		BasketAll.on(all => {
			dispatch(setBasketAll(all))
		})

		return () => {
			socketUsers.off()
		}
	}, [dispatch])

	useEffect(() => {
		let delay = nav ? 0 : 500,
			timer = setTimeout(() => setShow(nav), delay)

		return () => {
			clearTimeout(timer)
		}
	}, [nav])

	const navSwitch = () => dispatch(setOpenNav())
	const categoryChange = (category) => {
		dispatch(setBookmarkSearch(category))
		CategoryChange.emit(category)
		dispatch(setOpenNav())
	}

	const logout = () => {
		dispatch(setOpenNav())
		ioLogout.emit('isLogout')
	}

	const addBookmark = () => BookmarkEditEmit.emit(null)

	const settings = () => {
		dispatch(setOpenNav())
		dispatch(setSettingsEdit())
	}

	if(!show) return false

	return (<div className='navigation'>

		<span onClick={() => navSwitch()} />

		<div className='navigation-users'>
			{users ? users.reduction ? <>На сайте: <span>{users.users}.</span></> : <>На сайте: <span>{users.users},</span><span>{users.login}</span></> : null}
		</div>

		{all || basket_all ? <div className='navigation-all'>
			<>У вас:
				{all ? <span>{all} {declination(all, ['закладок', 'закладка', 'закладки'])}.</span> : null}
				{basket_all ? <span>{basket_all} {declination(basket_all, ['закладок', 'закладка', 'закладки'])} в корзине.</span> : null}
			</>
		</div>: ''}

		<div className='navigation-nav'>
			{!!menu ? menu
				.filter(el => all <= 0 ? el.value !== 'last' : el.value)
				.filter(el => basket_all <= 0 ? el.value !== 'ibox' : el.value)
				.map(el => {
				return (<span
					key={el.value}
					onClick={() => el.value !== active ? categoryChange(el.value) : null}
					className={[
						el.value === active && 'active-nav',
						'icon' in el && el.icon
					].filter(el => !!el).join(' ')}
				>
					<i/>{el.name} {el.value === 'ibox' ? <>({basket_all} шт.)</> : ''}
				</span>)
			}) : ''}
		</div>

		<div className='navigation-nav-add'>
			<span onClick={() => addBookmark()}><i/>Добавить закладку</span>
		</div>

		<div className='navigation-dop'>
			<span onClick={() => settings()}>Настройки<i/></span>
			<span onClick={() => logout()}>Выход<i/></span>
		</div>

		<div className='navigation-info'>
			<div>{rect_version}</div>
			<div>{copyright}</div>
		</div>

	</div>)
}

export default Navigation