function ComponentGeoLocation(props) {
	const { latitude, longitude, accuracyRadius } = props

	if(!latitude || !longitude) return false

	return (<div>
		<h2>Location</h2><p>
		<span>Latitude is</span> {latitude}&deg;<br/>
		<span>Longitude is</span> {longitude}&deg;<br/>
		{accuracyRadius ? <><span>Radius</span> {accuracyRadius} <span>Km.</span><br/></> : null}
		<a target="_blank" rel="noopener noreferrer" href={'https://maps.yandex.ru/?text=' + latitude + ',' + longitude}>Yandex</a>
		&nbsp;&nbsp;&nbsp;&nbsp;
		<a target="_blank" rel="noopener noreferrer" href={'https://maps.google.com/?hl=ru&q=' + latitude + ',' + longitude}>Google</a>
	</p>
	</div>)
}

export default ComponentGeoLocation
