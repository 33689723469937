import { createSlice } from '@reduxjs/toolkit'

export const Information = createSlice({
	name: "information",
	initialState: {
		x_forwarded_for: null,
		user_agent: null,
		info: {
			name: null ,
			color: null,
			code: null
		},
		geoLite2: null,
		geoLite2Org: null
	},
	reducers: {
		setXForwardedFor: (state, data) => {
			state.x_forwarded_for = data.payload
		},
		setUserAgent: (state, data) => {
			state.user_agent = data.payload
		},
		setInfo: (state, data) => {
			const json = JSON.parse(data.payload)
			state.info = json || state.info
		},
		setGeoLite2: (state, data) => {
			state.geoLite2 = data.payload
		},
		setGeoLite2Org: (state, data) => {
			const json = JSON.parse(data.payload)
			state.geoLite2Org = json.organization || null
		}
	}
})

export const { setXForwardedFor, setUserAgent, setInfo, setGeoLite2, setGeoLite2Org } = Information.actions

export const XForwardedFor = (state) => state.information.x_forwarded_for
export const User_Agent = (state) => state.information.user_agent
export const InfoIP = (state) => state.information.info
export const GeoLite2 = (state) => state.information.geoLite2
export const IGeoLite2Org = (state) => state.information.geoLite2Org

export default Information.reducer
