import { useState, useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import './index.scss'

import { formatDate, arrMonthsCalendar, arrWeekDaysMini, getNumberOfDays } from '../../config'
import { holidaysArray, setHolidaysArray } from '../../store/info_server'


function UiCalendar(props) {
	const { value, name, updateDate } = props

	const holidays = useSelector(holidaysArray)
	const dispatch = useDispatch()

	const [date, setDate] = useState(new Date())
	const [objDate, setObjDate] = useState(null)
	const [calendar, setCalendar] = useState(null)

	useEffect(() => {
		let todayDate = formatDate(new Date()),
			obj = formatDate(date, true),
			valueDate = formatDate(value),
			calendar = []

		for (let i = 0; i < obj.first_week; i++) {
			calendar.push({day: ''})
		}

		for (let i = 0; i < obj.days; i++) {
			if (+valueDate.year === +obj.year && +valueDate.month === +obj.month && +valueDate.day === i + 1) {
				calendar.push({day: i + 1, value: true})
			} else if (+todayDate.year === +obj.year && +todayDate.month === +obj.month && +todayDate.day === i + 1) {
				calendar.push({
					day: i + 1,
					today: true,
					counter: getNumberOfDays(value, new Date())
				})
			} else calendar.push({day: i + 1})
		}

		const calendarEnd = (Math.ceil(calendar.length / 7) * 7) - calendar.length
		for (let i = 0; i < calendarEnd; i++) {
			calendar.push({day: ''})
		}

		setObjDate(obj)
		setCalendar(calendar)

		const weekends = (arr) => {
			let calendar2 = calendar.map(el => {
				if (el.day) {
					const day = String(`${el.day}.${obj.month + 1}.${obj.year}`)
					const DAY = arr.filter(el => el[0] === day)[0] || false

					if (DAY) return {
						day: el.day,
						today: !!el.today,
						value: !!el.value,
						counter: el.counter,
						weekend: !!DAY && DAY[1]
					}
					else return {
						day: el.day,
						today: !!el.today,
						value: !!el.value,
						counter: el.counter
					}

				} else return {
					day: ''
				}
			})

			setCalendar(calendar2)
		}


		if(holidays.year.includes(obj.year)) {

			weekends(holidays.day) // Массив праздников и выходных

		} else {

			async function fetchMoviesJSON() {
				return await fetch(`https://api.bf5.ru/weekend/${obj.year}`, {method: 'GET'})
			}

			fetchMoviesJSON().then(response => {
				return response.json()
			}).then(data => {
				//if('day' in data) weekends(data.day) // Массив праздников и выходных
				dispatch(setHolidaysArray(data))
			})

		}

	}, [date, value, holidays, dispatch])


	const previousMonth = (year, month) => {
		const D = new Date(year, month, 1)
		D.setMonth(D.getMonth() - 1)
		setDate(D)
	}

	const nextMonth = (year, month) => {
		const D = new Date(year, month, 1)
		D.setMonth(D.getMonth() + 1)
		setDate(D)
	}

	const daySelection = (name, year, month, day) => {
		updateDate(name, `${year}-${month + 1}-${day}`)
	}

	if(!objDate || !calendar) return null

	return <div className='ui-calendar'>

		<div className='ui-calendar-month'>
			<span onClick={() => previousMonth(objDate.year, objDate.month)}>&#8592;</span>
			<span>{arrMonthsCalendar[objDate.month]}, {objDate.year}</span>
			<span onClick={() => nextMonth(objDate.year, objDate.month)}>&#8594;</span>
		</div>

		<div className='ui-calendar-week'>
			{arrWeekDaysMini.map((el, i, arr) => <span key={i}>{arr[i + 1]}</span>)}
		</div>

		<div className='ui-calendar-days'>
			{calendar ? calendar.map((el, i) => <span
				key={i}
				className={el.weekend}
				onClick={() => el.day === '' ? false : daySelection(name, objDate.year, objDate.month, el.day)}
			>
				{el.value ? <b className='value'>{el.day}</b> : el.today ? <b>{el.day}</b> : el.day}
				{el.counter ? <sup>{el.counter}</sup> : null}
			</span>) : null}
		</div>

	</div>
}

export default UiCalendar