import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {setUserIP, valLogin} from '../store/authorization'

import Authorization from './authorization'
import Token from './authorization/token'
import Registration from './authorization/registration'
import Bookmarks from './bookmarks'
import Error from './error'
import Loader from '../components/loader'
import UserInformation from "./user_information";

import { Info, ipAddress, UserAgent, yoIp2 } from '../api'
import { setInfo, setUserAgent, setXForwardedFor } from '../store/user-information'

function PageRoutes(props) {
	const loginIndex = +props.index

	const dispatch = useDispatch()

	useEffect(() => {
		//ipAddress.emit()
		//yoIp2.emit()
		//UserAgent.emit()
		//Info.emit()

		ipAddress.on(data => {
			dispatch(setUserIP(data))
		})

		yoIp2.on(data => {
			dispatch(setXForwardedFor(data))
		})

		UserAgent.on(data => {
			dispatch(setUserAgent(data))
		})

		Info.on(data => {
			dispatch(setInfo(data))
		})

		return () => {
			yoIp2.off()
			UserAgent.off()
			Info.off()
			ipAddress.off()
		}
	}, [dispatch])

	// Авторизация
	if(loginIndex === 0 || loginIndex === 4) return (<>
		<Routes>
			<Route index element={<Authorization />} />
			<Route path="/ip" element={<UserInformation />} />
			<Route path="/ip/:information_ip" element={<UserInformation />} />
			<Route path="*" element={<Error />} />
		</Routes>
	</>)

	// Токен
	if(loginIndex === 2) return (<>
		<Routes>
			<Route index element={<Token />} />
			<Route path="/ip" element={<UserInformation />} />
			<Route path="/ip/:information_ip" element={<UserInformation />} />
			<Route path="*" element={<Error />} />
		</Routes>
	</>)

	// Регистрация
	if(loginIndex === 3) return (<>
		<Routes>
			<Route index element={<Registration />} />
			<Route path="/ip" element={<UserInformation />} />
			<Route path="/ip/:information_ip" element={<UserInformation />} />
			<Route path="*" element={<Error />} />
		</Routes>
	</>)

	// Закладки
	if(loginIndex === 1) return (<>
		<Routes>
			<Route index element={<Bookmarks />} />
			<Route path="/ip" element={<UserInformation />} />
			<Route path="/ip/:information_ip" element={<UserInformation />} />
			<Route path="*" element={<Error />} />
		</Routes>
	</>)

	return <Loader />
}


function Page() {
	const login = useSelector(valLogin)
	return <PageRoutes index={login}/>
}

export default Page