import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setGeoLite2Org, IGeoLite2Org, XForwardedFor } from '../../store/user-information'
import { GeoLite2Org } from '../../api'
import { userIP } from '../../store/authorization'

function ComponentOrg() {
	const ip = useSelector(userIP)
	const ip2 = useSelector(XForwardedFor)
	const organization = useSelector(IGeoLite2Org)
	const dispatch = useDispatch()

	useEffect(() => {
		GeoLite2Org.emit(ip || ip2)

		GeoLite2Org.on(data => {
			dispatch(setGeoLite2Org(data))
		})

		return () => {
			GeoLite2Org.off()
		}
	}, [dispatch, ip, ip2])

	return (<div>
			<h2>ASN name (ISP)</h2>
			<p><span/>{organization}</p>
	</div>)
}

export default ComponentOrg