import { createSlice } from '@reduxjs/toolkit'

export const Socket = createSlice({
	name: "tv",
	initialState: {
		bind: {
			start: null,
			stop: null,
			title: "Что? Где? Когда?",
			info: null,
			category: null,
			show: 0
		},
		programme: []
	},
	reducers: {
		setTvBind: (state, tv) => {
			state.bind = JSON.parse(tv.payload)
		},
		setTvProgramme: (state, tv) => {
			state.programme = tv.payload
		},
	}
})

export const {
	setTvBind,
	setTvProgramme
} = Socket.actions

export const dataTvBind = (state) => state.tv.bind
export const dataTvProgramme = (state) => state.tv.programme

export default Socket.reducer


