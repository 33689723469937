import { useEffect, useState } from 'react'

function ComponentHtmlGeolocation() {
	const [geolocation, setGeolocation] = useState(null)

	useEffect(() => {
		setGeolocation(navigator.geolocation ? 'Поддерживается' : 'Не поддерживается в вашем браузере')
	}, [])

	return (<div><div>
		<h2>HTML5 GEOLOCATION API</h2><p>{geolocation}</p>
	</div></div>)
}

export default ComponentHtmlGeolocation