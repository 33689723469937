import { createSlice } from '@reduxjs/toolkit'

export const Authorization = createSlice({
	name: "authorization",
	initialState: {
		login: 100, // Код для типа страниц, логин, токен, ... (100 - просто большое число)
		settings: false, // Страница редактирования настроек
		categories: null, // "ADMIN"
		full_name: null, // "Андрей"
		gender: null, // "M"
		//dob: null, // ???
		email: null, // "andrey@shstrov.pro"
		tel: null, // "79104617048"
		atoken: null, // "1",
		target: null, // "_blank",
		reklama: [], // "1,2,3,4",
		ip: null, // ip адрес пользователя
		ip4: null, // Доступ по SSH к серверу "94.29.124.18",
		//menu: null, // "last,torrent,my_vip,home,money,digital,school,info,nvg,work,news,video,paket,megafon,foto,cse,sport,moto,avto,nts,synergy,xxl,ibox",
		adminmenu: null, // "2",
		click: null, // "1", // Показывать клики или нет
		isearch: null, // "#digitalcenter:Работа,\n#yandex:Яндекс карта,\n#info:Для работы,\n#read:Почитать,\n#version:--version",
		day_timer: null, // "2022-04-03T21:00:00.000Z"
		geo: '55.751675,37.618814',
		geo_html: null
	},
	reducers: {
		setLogin: (state, status) => {
			state.login = status.payload
		},
		setSettingsEdit: (state) => { // Страница редактирования настроек
			state.settings = !state.settings
		},
		setUserIP: (state, ip) => {
			state.ip = ip.payload
		},
		setGeoHtml: (state, geo) => {
			state.geo_html = {
				latitude: geo.payload.latitude,
				longitude: geo.payload.longitude,
				accuracy: geo.payload.accuracy
			}
		},
		setInfoUser: (state, date) => {
			const obj = JSON.parse(date.payload),
				json = obj.result

			state.categories = json.categories
			state.full_name = json.full_name
			state.gender = json.gender
			//state.dob = json.dob
			state.email = json.email
			state.tel = json.tel
			state.atoken = json.atoken
			state.target = json.target
			state.reklama = json.reklama
			state.ip4 = json.ip4
			//state.menu = json.menu
			state.adminmenu = json.adminmenu
			state.click = json.click
			state.isearch = json.isearch
			state.day_timer = json.day_timer
			state.geo = json.geo
		}
	}
})

export const { setLogin, setSettingsEdit, setInfoUser, setGeoHtml, setUserIP } = Authorization.actions

export const valLogin = (state) => state.authorization.login
export const settingsEdit = (state) => state.authorization.settings
export const userIP = (state) => state.authorization.ip
export const infoUser = (state) => {
	return {
		categories: state.authorization.categories,
		full_name: state.authorization.full_name,
		gender: state.authorization.gender,
		//dob: state.authorization.dob,
		email: state.authorization.email,
		tel: state.authorization.tel,
		atoken: state.authorization.atoken,
		target: state.authorization.target,
		reklama: state.authorization.reklama,
		ip4: state.authorization.ip4,
		//menu: state.authorization.menu,
		adminmenu: state.authorization.adminmenu,
		click: state.authorization.click,
		isearch: state.authorization.isearch,
		day_timer: state.authorization.day_timer,
		geo: state.authorization.geo
	}
}

export const adminmenu = (state) => state.authorization.adminmenu
export const reklama = (state) => state.authorization.reklama
export const geoHtml = (state) => state.authorization.geo_html

export default Authorization.reducer
