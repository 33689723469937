import { useEffect, useState } from 'react'

function ComponentHtmlGeolocation2() {
	const [geolocation, setGeolocation] = useState(null)

	// getCurrentPosition
	const getPosition = data => {
		if(data && 'coords' in data) {

			let latitude = data.coords.latitude
			let longitude = data.coords.longitude

			latitude = +latitude.toFixed(7)
			longitude = +longitude.toFixed(7)

			setGeolocation({
				latitude: latitude,
				longitude: longitude,
				accuracy: Math.ceil(data.coords.accuracy)
			})

		}
	}

	const errorGeo = err => {
		console.warn(`ERROR(${err.code}): ${err.message}`)
	}

	useEffect(() => {
		const timer = setInterval(getPosition, 5000)

		return () => {
			clearInterval(timer)
		}
	})

	useEffect(() => {
		const options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		}

		navigator.geolocation.getCurrentPosition(getPosition, errorGeo, options)
	}, [])


	if(!geolocation) return null

	const { latitude, longitude, accuracy } = geolocation

	return (<div><div>
		<h2>HTML5 GEOLOCATION</h2><p>
		<span>Latitude is</span> {latitude}&deg;<br/>
		<span>Longitude is</span> {longitude}&deg;<br/>
		<span>Radius</span> {accuracy} <span>meters.</span><br/>
		<a target="_blank" rel="noopener noreferrer" href={'https://maps.yandex.ru/?text=' + latitude + ',' + longitude}>Yandex</a>
		&nbsp;&nbsp;&nbsp;&nbsp;
		<a target="_blank" rel="noopener noreferrer" href={'https://maps.google.com/?hl=ru&q=' + latitude + ',' + longitude}>Google</a>
	</p>
	</div></div>)
}

export default ComponentHtmlGeolocation2
