import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import './index.scss'

import ComponentGeoLite2 from '../../components/geo'
import ComponentPixelRatio from '../../components/geo/pixel'
import ComponentTouch from '../../components/geo/touch'
import ComponentWidthHeight from '../../components/geo/width-height'
import ComponentDepth from '../../components/geo/depth'
import ComponentClock from '../../components/geo/clock'
import ComponentCookie from '../../components/geo/cookie'
import ComponentHtmlGeolocation from '../../components/geo/html-geo'
import ComponentHtmlGeolocation2 from '../../components/geo/html-geo-location'

import { Ready } from '../../api'
import { Helmet } from 'react-helmet'

import {setUserIP, userIP} from '../../store/authorization'
import {XForwardedFor, User_Agent, InfoIP, setXForwardedFor} from '../../store/user-information'

function UserInformation() {
	const { information_ip } = useParams() // Конкретный IP

	const ip = useSelector(userIP)
	const ip2 = useSelector(XForwardedFor)
	const user_agent = useSelector(User_Agent)
	const info = useSelector(InfoIP)
	const dispatch = useDispatch()

	useEffect(() => {
		Ready.ready()
	}, [])

	// Конкретный IP
	useEffect(() => {
		if(information_ip) {
			dispatch(setUserIP(information_ip))
			dispatch(setXForwardedFor(information_ip))
		}
	}, [dispatch, information_ip])

	return (<>
		<Helmet>
			<title>IP</title>
			<meta name="description" content="IP" />
		</Helmet>

		<div className="user-information">

			<div>
				<h1>Если у тебя паранойя, это не значит, что за тобой никто не следит.</h1>
				<pre>
					.'\   /'.<br/>
					.' .-. - .-. '.<br/>
					..._:  .-.   .-.  :_...<br/>
					.'   '-.( <span style={{color: info.color}}>o</span> ) ( <span style={{color: info.color}}>o</span> ).-'   '.<br/>
					:  _    _ _ ~(_)~ _ _    _  :<br/>
					:  /:   ' .-=_   _=-.  '   ;\  :<br/>
					:   :|-.._  '     '  _..-|:   :<br/>
					:    :| | :-:-.-:-:'| |:'   :<br/>
					'.     .| | | | | | |.'   .'<br/>
					'.     -:_|_|_|_:-'   .'<br/>
					'-._           _.-'<br/>
					 ''------''<br/>
					 '-\__|__/-'<br/>
					<br/>
				</pre>
				<span style={{color: info.color}}>
					{info.name}
				</span>
				{info.code ? <i className={info.code} /> : null}
			</div>

			<div className='user-information-ip'>
				{ip ? <div><h2>IP</h2><p><span/>{ip}</p></div> : null}
				{(ip2 && ip !== ip2) ? <div><h2>IP</h2><p><span/>{ip2}</p></div> : null}
			</div>

			<ComponentGeoLite2 />

			{!information_ip ? <>
				<div>
					<div><h2>USER-AGENT</h2><span>{user_agent}</span></div>
				</div>

				<ComponentPixelRatio />
				<ComponentTouch />
				<ComponentWidthHeight />
				<ComponentDepth />
				<ComponentClock />
				<ComponentCookie />
				<ComponentHtmlGeolocation />
				<ComponentHtmlGeolocation2 />
			</> : null}

			<div className='ip-footer'>
				<div>
					<p>
						This product includes GeoLite2 data created by MaxMind, available from<br/>
						<a target="_blank" rel="noopener noreferrer" href="https://www.maxmind.com">https://www.maxmind.com</a>
					</p>
				</div>
			</div>

		</div>
		</>)
}

export default UserInformation