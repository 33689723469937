import { useEffect, useState } from 'react'

function ComponentWidthHeight() {
	const [clientSize, setClientSize] = useState(null)
	const [screenSize, setScreenSize] = useState(null)

	useEffect(() => {
		function handleResize() {
			setClientSize({
				//width: window.innerWidth,
				width: document.body.parentElement.clientWidth,
				//height: window.innerHeight,
				height: document.body.parentElement.clientHeight,
			})
		}
		window.addEventListener("resize", handleResize)
		handleResize()
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	useEffect(() => {
		function handleResize() {
			setScreenSize({
				//width: window.innerWidth,
				width: window.screen.width,
				//height: window.innerHeight,
				height: window.screen.height,
			})
		}
		window.addEventListener("resize", handleResize)
		handleResize()
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	if(!clientSize && !screenSize) return null

	return (<>

		<div><div>
			<h2>BROWSER RESOLUTION</h2><p>
				<span>Weight: </span>{clientSize.width} <span>px</span><br/>
				<span>Height: </span>{clientSize.height} <span>px</span>
			</p>
		</div></div>

		<div><div>
			<h2>DISPLAY RESOLUTION</h2><p>
				<span>Weight: </span>{screenSize.width} <span>px</span><br/>
				<span>Height: </span>{screenSize.height} <span>px</span>
			</p>
		</div></div>

	</>)
}

export default ComponentWidthHeight