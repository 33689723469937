import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'

import Bookmark from '../../components/bookmark'
import ImageCrop from '../../components/image_crop'
import UiInput from '../../ui/input'
import UiSelect from '../../ui/select'

import { BookmarkEditClose, BookmarkEditID } from '../../api'
import { infoUser } from '../../store/authorization'
import { allBookmarks, setSearch } from '../../store/bookmarks'
import {navMenu, navMenuUser, vipBookmark} from '../../config'
import Message from '../../components/message'

function EditBookmark(props) {
	const { edit } = props
	const { id, url, info, description, color, nomer, col, tip, vip, IDuser } = edit

	const userInfo = useSelector(infoUser)
	const all_bookmarks = useSelector(allBookmarks)
	const [values, setValues] = useState(edit)
	const dispatch = useDispatch()

	const editClose = (id) => BookmarkEditClose.limit(id, all_bookmarks)

	const cat = userInfo.categories === 'ADMIN' ? navMenu : navMenuUser

	const catSelect = cat.filter(el => el.value !== '' && el.value !== 'last' && el.value !== 'ibox')

	const handleChange = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		setValues({...values, [name]: value})
		BookmarkEditID.bookmarkEdit(id, name, value)

		dispatch(setSearch(''))
	}

	const savePhoto = (id, name, photo) => {
		if(!photo) return null

		const width = 180 * 2
		const height = +width

		const canvas = document.createElement("canvas")
		canvas.width = width
		canvas.height = height
		canvas.getContext("2d").drawImage(photo, 0,0, width, height)

		const img = canvas.toDataURL('image/webp')

		BookmarkEditID.bookmarkEdit(id, name, img)
		setValues({...values, [name]: img})
	}

	return (<>
		<Helmet>
			<title>Редактирование</title>
			<meta name="description" content="Редактирование" />
		</Helmet>

		<div className='edit-bookmark'>

			<div>

				<span className='edit-bookmark-close' onClick={() => editClose(id)} >Закрыть</span>

				<h1>Редактирование закладки</h1>

				<div>
					<Message />
				</div>

				<Bookmark bookmark={values} show={false} />

				<div className='edit-bookmark-settings'>

					<div>

						<h2>Редактирование фото</h2>

						<ImageCrop id={id} name='img' savePhoto={savePhoto} />

					</div>

					<div>

						<h2>Настройки закладки</h2>

						<label>
							<p>URL:</p>
							<UiInput
								type="text"
								name='url'
								value={values.url ? values.url : url ? url : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Описание закладки:</p>
							<UiInput
								type="text"
								name='info'
								value={values.info ? values.info : info ? info : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Поисковые слова:</p>
							<UiInput
								type="text"
								name='description'
								value={values.description ? values.description : description ? description : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Цвет рамки:</p>
							<UiInput
								type="color"
								name='color'
								value={values.color ? values.color : color ? color : '#FFFFFF'}
								handleChange={handleChange}
							/>
						</label>

					</div>

					<div>
						<h2>Дополнительные настройки</h2>

						<label>
							<p>Категория закладки:</p>
							<UiSelect
								list={catSelect}
								name="tip"
								value={values.tip ? values.tip : tip}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Категория закладки:</p>
							<UiSelect
								list={vipBookmark}
								name="vip"
								value={values.vip ? values.vip : vip}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Порядковый номер в группе:</p>
							<UiInput
								type="text"
								name='nomer'
								value={values.nomer ? values.nomer : nomer}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Количество кликов по закладке:</p>
							<UiInput
								type="text"
								name='col'
								value={values.col ? values.col : col}
								handleChange={handleChange}
							/>
						</label>

					</div>

				</div>
			</div>


			{userInfo.categories === 'ADMIN' ?

				<div>
					ID: {id}
					<br/>
					USER ID: {IDuser ? IDuser : 'Сделать вывод ID'} {/*<UiInput
						type="text"
						name='IDuser'
						value={values.IDuser ? values.IDuser : IDuser}
						handleChange={handleChange}
					/>*/}
					<br/>
					<br/>
					Сделать вывод даты создания закладки и даты последнего перехода
				</div>

			: null}

		</div>

	</>)
}

export default EditBookmark