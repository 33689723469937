import { useEffect, useState } from 'react'

import './index.scss'

import { useDispatch, useSelector } from 'react-redux'
import { setTvBind, dataTvBind } from '../../../store/tv'
import { tvShow } from '../../../api'
import { arrMonths, arrWeekDays, formatDate } from '../../../config'

function InfoTvBind(props) {
	const { turnOn } = props

	const tv = useSelector(dataTvBind)
	const dispatch = useDispatch()

	const [show, setShow] = useState(false)

	useEffect(() => {
		tvShow.emit()

		tvShow.on(data => {
			dispatch(setTvBind(data))
		})

		return () => {
			tvShow.off()
		}
	}, [dispatch])

	useEffect(() => {

		if(tv.show) {

			const start = tv.start
			// const stop = tv.stop
			const title = 'Что? Где? Когда?'
			const title2 = tv.title.replace(/Что\? Где\? Когда\?/g, '')
			const info = tv.info
			const show = tv.show

			const dateStart = formatDate(start)

			const week = arrWeekDays[dateStart.week]
			const day = dateStart.day
			const month = arrMonths[dateStart.month]
			const hours = dateStart.hours
			const minutes = dateStart.minutes

			setShow({
				week,
				day,
				month,
				title,
				title2,
				info,
				show,
				hours,
				minutes
			})
		}

	}, [tv])

	if(!turnOn || !show) return null

	return (<div className='info-tv-bind'>
		<div className='big'>
			<div>
				<div>
					<h1>{show.week}, {show.day} {show.month} в {show.hours}:{show.minutes}, на первом канале</h1>
				</div>
				<div>
					<h1>{show.title}</h1>
					<h2>{show.title2}</h2>
					<p>{show.info}</p>
				</div>
			</div>
		</div>
	</div>)
}

export default InfoTvBind