import { useEffect, useState } from 'react'

function ComponentFlag(props) {
	const { flag, isInEuropeanUnion } = props

	const [imgYes, setImgYes] = useState(false)

	useEffect(() => {

		let img = new Image()
		img.src = '/media/flag/' + flag + '.png'
		img.onload = () => setImgYes(true)
		img.onerror = () => setImgYes(false)

	}, [flag])

	if(!imgYes) return null

	return (<div>
		<h2>FLAG</h2>
		<p className='flag'>
			<span>
				<img src={'/media/flag/' + flag + '.png'} alt={flag} title={flag} />
				</span>{isInEuropeanUnion && <span>
				<img src={'/media/flag/European-Union.png'} alt='European Union' title='European Union' />
			</span>}
		</p>
	</div>)
}

export default ComponentFlag