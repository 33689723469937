import React, { useState, useRef, ChangeEvent, SyntheticEvent } from 'react'

import './index.scss'

import ReactCrop, {
	centerCrop,
	makeAspectCrop,
	//Crop,
	//PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvas-preview'
import { useDebounceEffect } from './debounce-effect'

import 'react-image-crop/src/ReactCrop.scss'
import UiButton from '../../ui/button'

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
	mediaWidth,
	mediaHeight,
	aspect,
) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: 'px',
				width: 180,
				height: 180,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	)
}

export default function ImageCrop(props) {
	const { id, name, savePhoto } = props

	const [imgSrc, setImgSrc] = useState('')
	const previewCanvasRef = useRef(null)
	const imgRef = useRef(null)
	const [crop, setCrop] = useState({
		unit: 'px', // Can be 'px' or '%'
		x: 0,
		y: 0,
		width: 180,
		height: 180
	})
	const [completedCrop, setCompletedCrop] = useState()
	//const [scale, setScale] = useState(1)
	//const [rotate, setRotate] = useState(0)
	const [preview, setPreview] = useState(null)
	const aspect = 1 / 1

	function onSelectFile(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined) // Makes crop preview update between images.
			const reader = new FileReader()
			reader.addEventListener('load', () =>
				setImgSrc(reader.result.toString() || ''),
			)
			reader.readAsDataURL(e.target.files[0])
		}
	}

	function onImageLoad(e: SyntheticEvent<HTMLInputElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget
			setCrop(centerAspectCrop(width, height, aspect))
		}
	}

	useDebounceEffect(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {

				// We use canvasPreview as it's much faster than imgPreview.
				canvasPreview(
					imgRef.current,
					previewCanvasRef.current,
					completedCrop,
					//scale,
					//rotate,
				)

				setTimeout(() => {
					setPreview( previewCanvasRef.current )
				}, 300)

			}
		},
		100,
		[
			completedCrop,
			//scale,
			//rotate
		],
	)

	/*
	function handleToggleAspectClick() {
		if (aspect) {
			setAspect(undefined)
		} else if (imgRef.current) {
			const { width, height } = imgRef.current
			setAspect(16 / 9)
			setCrop(centerAspectCrop(width, height, 16 / 9))
		}
	}
	*/

	return (
		<div className='image-crop'>
			<div>
				<span>
					<input type="file" accept="image/*" onChange={onSelectFile} />
					<UiButton text="Прикрепить изображение" />
				</span>

				{/*
				<div>
					<label htmlFor="scale-input">Scale: </label>
					<input
						id="scale-input"
						type="number"
						step="0.1"
						value={scale}
						disabled={!imgSrc}
						onChange={(e) => setScale(Number(e.target.value))}
					/>
				</div>
				<div>
					<label htmlFor="rotate-input">Rotate: </label>
					<input
						id="rotate-input"
						type="number"
						value={rotate}
						disabled={!imgSrc}
						onChange={(e) =>
							setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
						}
					/>
				</div>
				<div>
					<button onClick={handleToggleAspectClick}>
						Toggle aspect {aspect ? 'off' : 'on'}
					</button>
				</div>
				*/}
			</div>
			<div>
				{Boolean(imgSrc) && (
					<ReactCrop
						crop={crop}
						onChange={(_, percentCrop) => setCrop(percentCrop)}
						onComplete={(c) => setCompletedCrop(c)}
						aspect
					>
						<img
							ref={imgRef}
							alt="Crop me"
							src={imgSrc}
							//style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
							onLoad={onImageLoad}
						/>
					</ReactCrop>
				)}
			</div>
			<div>
				{Boolean(completedCrop) && (<>

					<canvas
						ref={previewCanvasRef}
						style={{
							border: '1px solid black',
							objectFit: 'contain',
							width: completedCrop.width,
							height: completedCrop.height,
						}}
					/>

					<UiButton
						type='button'
						text='Применить'
						handleClick={() => savePhoto(id, name, preview)}
					/>

				</>)}

			</div>
		</div>
	)
}
