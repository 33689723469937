import { useEffect } from 'react'

import './index.scss'

import { useDispatch, useSelector } from 'react-redux'
import { dataTvProgramme, setTvProgramme } from '../../../store/tv'

import { tvProgramme } from '../../../api'

function InfoTv(props) {
	const { turnOn } = props

	const programme = useSelector(dataTvProgramme)
	const dispatch = useDispatch()

	useEffect(() => {
		tvProgramme.emit()

		tvProgramme.on(data => {
			dispatch(setTvProgramme(data))
		})

		return () => {
			tvProgramme.off()
		}
	}, [dispatch])

	if(!turnOn) return false

	if(!programme || !programme.length) return null

	return (<div className='info-tv'>
		<div className='big'>
			<div>

				<h1>Кино по ТВ</h1>
				<div>
					{programme.map(item => <dl key={item.name + '_' + item.time + '_' + item.channel}>
						<dt><b>{item.time}</b></dt>
						<dd>
							<b>{item.name}</b>
							<p><span>{item.category}</span><b>&laquo;{item.channel}&raquo;</b></p>
						</dd>
					</dl>)}
				</div>

			</div>
		</div>
	</div>)
}

export default InfoTv