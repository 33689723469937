import { useEffect, useState } from 'react'

function ComponentDepth() {
	const [depth, setDepth] = useState(null)

	useEffect(() => {
		setDepth({
			colorDepth: window.screen.colorDepth,
			pixelDepth: window.screen.pixelDepth
		})
	}, [])

	if(!depth) return null

	return (<div><div>
		<h2>SCREEN</h2><p>{depth.pixelDepth}<span> bit depth</span><br/>{depth.colorDepth}<span> bit color palette depth</span></p>
	</div></div>)
}

export default ComponentDepth