import './index.scss'

function UiButton(props) {
	const { type, text, skin = 'big', handleClick } = props

	const clickHandle = (event) => handleClick(event)

	return <button
		className={[
			'ui-button',
			 `ui-button-${skin}`
		].join(' ')}
		type={type}
		onClick={(e) => handleClick ? clickHandle(e) : null}
	>
		{text}
	</button>
}

export default UiButton