import { useEffect, useState } from 'react'

function ComponentClock() {
	const [date, setDate] = useState(Date())

	useEffect(() => {
		setTimeout(() => setDate(Date()), 1000)
	})

	return (<div><div>
		<h2>DATE</h2><p>{date}</p>
	</div></div>)
}

export default ComponentClock