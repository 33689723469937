import './index.scss'

function UiTextarea(props) {
	const handleChange = (event) => props.handleChange(event)

	return <textarea
		className='ui-textarea'
		name={props.name}
		value={props.value}
		onChange={(e) => handleChange(e)}
		autoComplete="off"
	/>
}

export default UiTextarea