import { useEffect, useState } from 'react'

function ComponentTouch() {
	const [touch, setTouch] = useState()

	useEffect(() => {
		setTouch('ontouchstart' in document.documentElement)
	}, [])

	return (<div><div>
		<h2>TOUCH SCREEN DEVICES</h2><p>{touch ? 'true' : 'false'}</p>
	</div></div>)
}

export default ComponentTouch