import { useRef } from 'react'

import './index.scss'

function UiInput(props) {
	const { type, name, value, skin, handleChange } = props
	const input = useRef()

	const cleaning = (event) => {
		event.preventDefault()
		input.current.value = type === 'color' ? '#000000' : ''
		handleChange({target: input.current})
	}

	const change = (event) => {
		event.preventDefault()
		handleChange(event)
	}

	return (<div
		className={[
			'ui-input',
			skin && `ui-input-${skin}`,
			type === 'color' && value === '#000000' && `ui-input-transparent`,
			value === '' && 'ui-input-null'
		].filter(el => !!el).join(' ')}
	>
		<input
			autoComplete='new-password'
			ref={input}
			type={type}
			name={name}
			value={value}
			onChange={(e) => change(e)}
		/>
			<span onClick={(e) => cleaning(e)} />
	</div>)
}

export default UiInput