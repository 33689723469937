import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { messagesInfo } from '../../api'
import { valMessage, valTimer, setMessage } from '../../store/socket'

function Message(props) {
	const { divClass } = props
	const message = useSelector(valMessage)
	const timer = useSelector(valTimer)
	const dispatch = useDispatch()

	useEffect(() => {
		messagesInfo.on(data => {
			dispatch(setMessage(data))
		})

		return () => {
			messagesInfo.off()
		}
	}, [dispatch])

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(setMessage({}))
		}, timer)
		return () => clearInterval(interval)
	}, [timer, dispatch])

	return (<>
		<span className={divClass} style={{color: 'red'}}>{message}</span>
	</>)
}

export default Message