import { useEffect } from 'react'

function ComponentCookie() {

	useEffect(() => {
		document.cookie = "test=1s; path=/ip; expires=" + new Date(Date.now() + 10e3).toUTCString();
	}, [])

	return (<div><div>
		<h2>COOKIE</h2><p>{document.cookie.includes('test=1s') ? 'Запись в cookie доступна' : 'Запись в cookie недоступна'}</p>
	</div></div>)
}

export default ComponentCookie