import React, {useEffect, useState} from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'

import { infoUser, setSettingsEdit, userIP } from '../../store/authorization'
import { setSearch } from '../../store/bookmarks'

import UiInput from '../../ui/input'
import UiSelect from '../../ui/select'

import { BookmarkSearchValue, SettingsUpdate } from '../../api'
import UiTextarea from '../../ui/textarea'
import UiButton from '../../ui/button'
import UiCalendar from '../../ui/calendar'
import Message from '../../components/message'

function Settings() {
	const info = useSelector(infoUser)
	const user_ip = useSelector(userIP)
	const dispatch = useDispatch()

	const [reklama, setReklama] = useState(!!info.reklama ? [...new Set(info.reklama.split(',').filter(() => true).map(el => Number(el)))] : [])

	useEffect(() => {
		BookmarkSearchValue.emit()

		BookmarkSearchValue.on(data => {
			dispatch(setSearch(data))
		})

		return () => {
			BookmarkSearchValue.off()
		}
	}, [dispatch])

	const settingsClose = () => {
		dispatch(setSettingsEdit())
	}

	const sshIpOpen = () => SettingsUpdate.settingsEdit('ip4', user_ip)
	const sshIpClose = () => SettingsUpdate.settingsEdit('ip4', '10.0.0.0')

	const handleChange = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		SettingsUpdate.settingsEdit(name, value)
	}



	const handleChangeReklama = (event) => {
		const target = event.target
		const value = target.value
		const name = target.name

		let arr = [...reklama]
		arr = arr.filter(el => el !== 0).filter(el => el <= 7)

		if(name === 'r_1') value === '1' ? arr.push(1) : arr = arr.filter(el => el !== 1)
		if(name === 'r_2') value === '1' ? arr.push(2) : arr = arr.filter(el => el !== 2)
		if(name === 'r_3') value === '1' ? arr.push(3) : arr = arr.filter(el => el !== 3)
		if(name === 'r_4') value === '1' ? arr.push(4) : arr = arr.filter(el => el !== 4)
		if(name === 'r_5') value === '1' ? arr.push(5) : arr = arr.filter(el => el !== 5)
		if(name === 'r_6') value === '1' ? arr.push(6) : arr = arr.filter(el => el !== 6)
		if(name === 'r_7') value === '1' ? arr.push(7) : arr = arr.filter(el => el !== 7)

		arr = arr.sort()
		arr = [...new Set(arr)]

		setReklama([...arr])
		SettingsUpdate.settingsEdit('reklama', String(arr))
	}

	const updateDateDayTimer = (name, value) => {
		SettingsUpdate.settingsEdit(name, value)
	}

	return (<>
		<Helmet>
			<title>Настройки</title>
			<meta name="description" content="Настройки" />
		</Helmet>


		<div className='edit-settings'>

			<div>

				<span className='edit-settings-close' onClick={() => settingsClose()} >Закрыть</span>

				<h1>Настройки</h1>

				<Message />

				{info.categories === "ADMIN" ? <>categories: {info.categories}</> : null}

				<div className='edit-settings-box'>

					<div>

						<h2>Профиль</h2>

						<label>
							<p>Имя:</p>
							<UiInput
								type="text"
								name='full_name'
								value={info.full_name ? info.full_name : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Пол:</p>
							<UiSelect
								list={[
									{value: 'M', name: 'Мужчина'},
									{value: 'Ж', name: 'Женщина'}
								]}
								name="gender"
								value={info.gender ? info.gender : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>E-mail:</p>
							<UiInput
								type="text"
								name='email'
								value={info.email ? info.email : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Телефон:</p>
							<UiInput
								type="text"
								name='tel'
								value={info.tel ? info.tel : ''}
								handleChange={handleChange}
							/>
						</label>

						{info.categories === "ADMIN" ? <label>
							<p>Авторизация по токену:</p>
							<UiSelect
								list={[
									{value: '1', name: 'Да'},
									{value: '0', name: 'Нет'}
								]}
								name="atoken"
								value={info.atoken ? info.atoken : ''}
								handleChange={handleChange}
							/>
						</label> : ''}

						{info.categories === "ADMIN" ? <label>
							<p>GEO координаты (Погода):</p>
							<UiInput
								type="text"
								name='geo'
								value={info.geo ? info.geo : ''}
								handleChange={handleChange}
							/>
						</label> : ''}

					</div>

					<div>

						<h2>Настройки</h2>

						<label>
							<p>Показывать количество кликов:</p>
							<UiSelect
								list={[
									{value: '1', name: 'Да'},
									{value: '0', name: 'Нет'}
								]}
								name="click"
								value={info.click ? info.click : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Открывать закладки:</p>
							<UiSelect
								list={[
									{value: '_blank', name: 'В новом окне'},
									{value: '_self', name: 'В текущем окне'}
								]}
								name="target"
								value={info.target ? info.target : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Быстрый поиск (#хештеги):</p>
							<UiTextarea
								name='isearch'
								value={info.isearch ? info.isearch : ''}
								handleChange={handleChange}
							/>
							<div>
								<b>Пример:</b><br/>
								<span>
									#work:Работа,<br/>
									#yandex:Яндекс
								</span>
							</div>
						</label>


						<h2>Информационные блоки</h2>
						<label>
							<p>Показывать погоду:</p>
							<UiSelect
								list={[
									{value: '', name: 'Нет'},
									{value: '1', name: 'Да'}
								]}
								name="r_1"
								value={reklama.indexOf(1) !== -1 ? '1' : ''}
								handleChange={handleChangeReklama}
							/>
						</label>

						<label>
							<p>Показывать календарь:</p>
							<UiSelect
								list={[
									{value: '', name: 'Нет'},
									{value: '1', name: 'Да'}
								]}
								name="r_6"
								value={reklama.indexOf(6) !== -1 ? '1' : ''}
								handleChange={handleChangeReklama}
							/>
						</label>

						<label>
							<p>Показывать geo:</p>
							<UiSelect
								list={[
									{value: '', name: 'Нет'},
									{value: '1', name: 'Да'}
								]}
								name="r_7"
								value={reklama.indexOf(7) !== -1 ? '1' : ''}
								handleChange={handleChangeReklama}
							/>
						</label>

						<label>
							<p>Показывать кино по тв:</p>
							<UiSelect
								list={[
									{value: '', name: 'Нет'},
									{value: '1', name: 'Да'}
								]}
								name="r_3"
								value={reklama.indexOf(3) !== -1 ? '1' : ''}
								handleChange={handleChangeReklama}
							/>
						</label>

						<label>
							<p>Показывать "Что? Где? Когда?":</p>
							<UiSelect
								list={[
									{value: '', name: 'Нет'},
									{value: '1', name: 'Да'}
								]}
								name="r_2"
								value={reklama.indexOf(2) !== -1 ? '1' : ''}
								handleChange={handleChangeReklama}
							/>
							<div>
								<br/>
								<b>Внимание!</b><br/>
								<span>
									Блоки отображаются на странице<br/>"Недавно используемые"
								</span>
							</div>
						</label>

					</div>

					{info.categories === "ADMIN" ? <div>

						<h2>Админские настройки</h2>

						<label>
							<p>Админское меню / настройки:</p>
							<UiSelect
								list={[
									{value: '0', name: 'Выключено'},
									{value: '1', name: 'Админка'},
									{value: '2', name: 'Информация о сервере (TopBar)'}
								]}
								name="adminmenu"
								value={info.adminmenu ? info.adminmenu : ''}
								handleChange={handleChange}
							/>
						</label>

						<label>
							<p>Информационные блоки:</p>
							<UiInput
								type="text"
								name='reklama'
								value={info.reklama ? info.reklama : ''}
								handleChange={handleChange}
							/>
							<div>
								<b>Значения:</b><br/>
								<span>
									1 - Погода<br/>
									2 - (ТВ) Что? Где? Когда?<br/>
									3 - (TB) Кино по ТВ<br/>
									4 - Колличество дней ношения линз<br/>
									5 - Clipboard (Буфер обмена)<br/>
									6 - Производственный календарь<br/>
									7 - GEO<br/>
								</span>
							</div>
						</label>

						<label>
							<p>Дата начала ношения линз:</p>
							<UiCalendar
								value={info.day_timer ? info.day_timer : ''}
								name='day_timer'
								updateDate={updateDateDayTimer}
							/>
						</label>

						<label>
							<p>SSH порт сервера / iptables:</p>
							<UiInput
								type="text"
								name='ip4'
								value={info.ip4 ? info.ip4 : ''}
								handleChange={handleChange}
							/>
							<div>
								<b>Текущий IP: </b>
								<span>{user_ip} </span>
								<UiButton
									skin='mini'
									type='button'
									text='Использовать'
									handleClick={sshIpOpen}
								/>
								<UiButton
									skin='mini'
									type='button'
									text='Закрыть'
									handleClick={sshIpClose}
								/>
							</div>
							<div>
								<b>SSH Tunnel:</b>
								<div>
									<code>
										ssh server_web -v -ND 127.0.0.1:5566
									</code>
								</div>
							</div>
						</label>

					</div> : null }

				</div>
			</div>

		</div>

	</>)
}

export default Settings

