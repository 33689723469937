import './index.scss'

function UiSelect(props) {
	const { value, name, list } = props

	const handleChange = (event) => props.handleChange(event)

	return <select
		className='ui-select'
		defaultValue={value}
		name={name}
		onChange={(e) => handleChange(e)}
	>
		{!!list ? list.map(el => <option key={el.value ? el.value : el} value={el.value ? el.value : el}>{el.name ? el.name : el}</option>) : ''}
	</select>
}

export default UiSelect