import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import AuthorizationReducer from './authorization'
import SocketReducer from './socket'
import BookmarksReducer from './bookmarks'
import ServerReducer from './info_server'
import WeatherReducer from './weather'
import TvReducer from './tv'
import LensesReducer from './lenses'
import InformationReducer from './user-information'

const reducers = combineReducers({
	authorization: AuthorizationReducer,
	socket: SocketReducer,
	bookmarks: BookmarksReducer,
	server: ServerReducer,
	weather: WeatherReducer,
	tv: TvReducer,
	lenses: LensesReducer,
	information: InformationReducer,
})

const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'authorization',
		'socket',
		'bookmarks',
		'server',
		'weather',
		'tv',
		'lenses',
		'information',
	]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	devTools: process.env.NODE_ENV !== 'production'
})

let persistor = persistStore(store)

export { store, persistor }
