import React, { useMemo, Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'

import './index.scss'

import Search from '../../components/search'
import Bookmark from '../../components/bookmark'
import DateSeparator from '../../components/date'
import EditBookmark from '../edit_bookmark'
import Settings from '../edit_settings'
import Navigation from '../../components/nav'
import AdminTop from '../../components/admin_top'
import InfoList from '../../components/info_list'

import {
	editBookmark,
	listBookmarks,
	loadingBookmarks,
	setListBookmarks,
	setUpdateBookmark,
	setEditBookmark,
	openNav,
	setOpenNav,
	allBookmarks,
	activeCategory,
	typeBookmarks,
	setSearchLenta,
	colorSearch,
	searchBookmarks
} from '../../store/bookmarks'
import {settingsEdit, setInfoUser, adminmenu, reklama, setGeoHtml, infoUser} from '../../store/authorization'
import { BookmarkEditEmit, BookmarkSearchLenta, Categories, SettingsEdit, SettingsUpdate, TileUp } from '../../api'
import { copyright, navMenu } from '../../config/index'
import Message from '../../components/message'
import Loader from '../../components/loader'

function BookmarksList(props) {
	const { list, type, loading } = props

	if(loading) return <Loader />

	// Ответ при пустом поиске
	if(type === 'search' && !list.length) return (<>
		<DateSeparator serialNumber={0} position='top' nextOpeningTime={null} empty={false} />
		<div>Такого нет в базе!</div>
	</>)

	if(list.length === 0) return (<>
		<DateSeparator empty={true} />
		<div>Нет закладок</div>
	</>)

	return list.map((el, i, list) => <Fragment key={el.id}>
		<DateSeparator openingTime={el.datainfo} serialNumber={i} position='top'
			nextOpeningTime={list.length === 1 || !!list[i + 1] ? list[i].datainfo : list[i + 1] ? list[i + 1].datainfo : null}
		empty={false} />
		<Bookmark bookmark={el} />
		<DateSeparator openingTime={el.datainfo} position='bottom' nextOpeningTime={list[i + 1] ? list[i + 1].datainfo : null} empty={false} />
	</Fragment>)
}

function PositionGeo() {
	const dispatch = useDispatch()

	// getCurrentPosition
	const getPositionGeo = data => {
		if(data && 'coords' in data) {

			let latitude = data.coords.latitude
			let longitude = data.coords.longitude

			latitude = +latitude.toFixed(7)
			longitude = +longitude.toFixed(7)

			dispatch(setGeoHtml({
				latitude,
				longitude,
				accuracy: data.coords.accuracy
			}))

			const geo = `${latitude},${longitude}`
			SettingsUpdate.settingsEdit('geo', geo)
		}
	}

	const error = err => {
		console.warn(`ERROR(${err.code}): ${err.message}`)
	}

	useEffect(() => {
		const timer = setInterval(getPositionGeo, 5000)

		return () => {
			clearInterval(timer)
		}
	})

	useEffect(() => {
		const options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		}

		navigator.geolocation.getCurrentPosition(getPositionGeo, error, options)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

function Bookmarks() {
	const all_bookmarks = useSelector(allBookmarks)
	const listStore = useSelector(listBookmarks)
	const loading = useSelector(loadingBookmarks)
	const typeStore = useSelector(typeBookmarks)
	const edit = useSelector(editBookmark)
	const settings = useSelector(settingsEdit)
	const nav = useSelector(openNav)
	const active_category = useSelector(activeCategory)
	const color_search = useSelector(colorSearch)
	const search_bookmarks = useSelector(searchBookmarks)
	const adminTop = useSelector(adminmenu)
	const reklamaList = useSelector(reklama)
	const userInfo = useSelector(infoUser)
	const dispatch = useDispatch()

	const list = useMemo(() => listStore, [listStore])

	const [bookmarks, setBookmarks] = useState(all_bookmarks)
	const [bookmarksSave, setBookmarksSave] = useState(30)
	const [bookmarksLoading, setBookmarksLoading] = useState(0)
	const [skinCategory, setSkinCategory] = useState(false)

	useEffect(() => {
		const skin = navMenu.filter(el => el.value === active_category)

		if(!!skin.length) 'skin' in skin[0] ? setSkinCategory(skin[0].skin) : setSkinCategory(false)
		else setSkinCategory(false)

	}, [active_category])


	useEffect(() => {
		Categories.emit()
		SettingsEdit.emit()
		BookmarkSearchLenta.emit()

		Categories.on(data => {
			dispatch(setListBookmarks(data))
		})

		TileUp.on(data => {
			dispatch(setUpdateBookmark(data))
		})

		BookmarkEditEmit.on(data => {
			dispatch(setEditBookmark(data))
		})

		SettingsEdit.on(data => {
			dispatch(setInfoUser(data))
		})

		BookmarkSearchLenta.on(data => {
			dispatch(setSearchLenta(data))
		})

		return () => {
			Categories.off()
			TileUp.off()
			BookmarkEditEmit.off()
			SettingsEdit.off()
			BookmarkSearchLenta.off()
		}
	}, [dispatch])

	useEffect(() => {
		setBookmarks(0)
		setBookmarksSave(50)
	}, [active_category])

	useEffect(() => {
		setBookmarks(all_bookmarks)
	}, [all_bookmarks])

	useEffect(() => {
		setBookmarksSave(all_bookmarks)
		if(!!bookmarks && bookmarks === bookmarksSave) Categories.limit(bookmarks, 50)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookmarksLoading])

	// ------------ scroll

	// Добавим функцию throttle:
	const throttle = (callee, timeout) => {
		let timer = null

		return function perform(...args) {
			if (timer) return

			timer = setTimeout(() => {
				callee(...args)

				clearTimeout(timer)
				timer = null
			}, timeout)
		}
	}

	useEffect(() => {

		const handleScroll = () => {
			const box = document.querySelector('.page-bookmarks-content-list')

			if(!box) return null

			const clientRect = box.getBoundingClientRect(),
				top = clientRect.top, // Позиция слайдера сверху
				height = box.clientHeight, // Высота блока
				win = window.innerHeight, // Высота окна браузера
				add = top + (height - win) < 600

			if(add) setBookmarksLoading(Math.random())
		}

		document.addEventListener("scroll", throttle(handleScroll, 250))

		return () => {
			document.removeEventListener("scroll", throttle(handleScroll, 250))
		}
	}, [])

	// ------------ END scroll

	const navSwitch = () => dispatch(setOpenNav())

	// Настройки
	if(settings) return <Settings />

	// Редактирование закладки
	if(edit) return <EditBookmark edit={edit} />

	// Список закладок
	return (<>
		<Helmet>
			<title>Закладки</title>
			<meta name="description" content="Закладки / Избранное" />
		</Helmet>

		<PositionGeo />

		<div className={[
			'page-bookmarks',
			nav && 'open-nav',
			color_search && `skin-${color_search}`,
			skinCategory && `skin-category-${skinCategory}`,
			search_bookmarks === '#alean' && `skin-category-alean`,
			search_bookmarks === '#alean_local' && `skin-category-alean`,
			search_bookmarks === '#alean_git' && `skin-category-alean`
		].filter(el => !!el).join(' ')}>

			<div className='page-bookmarks-menu'>
				<div>
					<Navigation />
				</div>
			</div>

			<div
				onClick={() => nav ? navSwitch() : null}
				className={[
				'page-bookmarks-content',
				nav && 'blackout'
			].filter(el => !!el).join(' ')}>

				{userInfo.categories === 'ADMIN' ? <AdminTop top={adminTop} /> : ''}

				<div className='page-bookmarks-content-box'>

					<Message divClass='page-bookmarks-message' />

					<div>
						<Search />
					</div>

				</div>

				{loading ? '' : <InfoList list={reklamaList} />}

				<div className='page-bookmarks-content-box page-bookmarks-content-box-center'>

					<div className='page-bookmarks-content-list'>
						<BookmarksList list={list} type={typeStore} loading={loading}/>
					</div>

				</div>

				<div className='page-bookmarks-content-footer'>
					{copyright}
				</div>

			</div>
		</div>
	</>)
}

export default Bookmarks
