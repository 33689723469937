import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ComponentOrg from './org'
import ComponentFlag from './flag'
import ComponentGeoLocation from './geo'

import { GeoLite2, setGeoLite2, XForwardedFor } from '../../store/user-information'
import { GeoLite2Inquiry } from '../../api'
import { userIP } from '../../store/authorization'

function ComponentGeoLite2() {
	const ip = useSelector(userIP)
	const ip2 = useSelector(XForwardedFor)
	const geo = useSelector(GeoLite2)
	const dispatch = useDispatch()

	useEffect(() => {
		GeoLite2Inquiry.emit(ip2 || ip )

		GeoLite2Inquiry.on(data => {
			dispatch(setGeoLite2(data))
		})

		return () => {
			GeoLite2Inquiry.off()
		}
	}, [dispatch, ip, ip2])

	if(!geo) return null;

	let json = JSON.parse(geo);
	let jsonCountry = 'country' in json ? json.country : false;
	let jsonRegisteredCountry = 'registeredCountry' in json ? json.registeredCountry : false;
	let jsonRepresentedCountry = 'representedCountry' in json ? json.representedCountry : false;
	let jsonContinent = 'continent' in json ? json.continent : false;
	let jsonCity = 'city' in json ? json.city : false;
	let jsonTraits = 'traits' in json ? json.traits : false;
	let jsonLocation = 'location' in json ? json.location : false;

	let isoCode = jsonCountry? 'isoCode' in jsonCountry ? jsonCountry.isoCode : null : null;
	let country = jsonCountry ? 'names' in jsonCountry ? jsonCountry.names.en : null : null;
	let isInEuropeanUnion = 'isInEuropeanUnion' in jsonCountry ? jsonCountry.isInEuropeanUnion : false;
	let registeredCountry = jsonRegisteredCountry ? 'names' in jsonRegisteredCountry ? jsonRegisteredCountry.names.en : null : null;
	let registeredCountryIsoCode = jsonRegisteredCountry ? 'isoCode' in jsonRegisteredCountry ? jsonRegisteredCountry.isoCode : null : null;
	let representedCountry = jsonRepresentedCountry ? 'names' in jsonRepresentedCountry ? jsonRepresentedCountry.names.en : null : null;
	let representedCountryIsoCode = jsonRepresentedCountry ? 'isoCode' in jsonRepresentedCountry ? jsonRepresentedCountry.isoCode : null : null;
	let continent = jsonContinent ? 'names' in jsonContinent ? jsonContinent.names.en : null : null;
	let city = jsonCity ? 'names' in jsonCity ? jsonCity.names.en : null : null;
	let network = jsonTraits ? 'network' in jsonTraits ? jsonTraits.network : null : null;
	let latitude = jsonLocation ? 'latitude' in jsonLocation ? jsonLocation.latitude : null : null;
	let longitude = jsonLocation ? 'longitude' in jsonLocation ? jsonLocation.longitude : null : null;
	let accuracyRadius = jsonLocation ? 'accuracyRadius' in jsonLocation ? jsonLocation.accuracyRadius : null : null;
	let timeZone = jsonLocation ? 'timeZone' in jsonLocation ? jsonLocation.timeZone : null : null;

	return (<div>

		{network ? <div><h2>network</h2><p><span/>{network}</p></div> : null}

		<ComponentOrg ip={ip} />

		{country ? <ComponentFlag flag={country.replace(' ','-')} isInEuropeanUnion={isInEuropeanUnion} />: null}

		{continent ? <div><h2>Continent</h2><p><span/>{continent}</p></div> : null}

		{country ? <div><h2>Country</h2><p><span/>{country}{isoCode ? <> ({isoCode})</> : null}</p></div> : null}

		{registeredCountry ? <div><h2>Registered Country</h2><p><span/>{registeredCountry}{registeredCountryIsoCode ? <> ({registeredCountryIsoCode})</> : null}</p></div> : null}

		{representedCountry ? <div><h2>Represented Country</h2><p><span/>{representedCountry}{representedCountryIsoCode ? <> ({representedCountryIsoCode})</> : null}</p></div> : null}

		{city ? <div><h2>City</h2><p><span/>{city}</p></div> : null}

		{(latitude && longitude) ? <ComponentGeoLocation latitude={latitude} longitude={longitude} accuracyRadius={accuracyRadius} /> : null}

		{timeZone ? <div><h2>Time Zone</h2><p><span/>{timeZone}</p></div> : null}

	</div>)
}

export default ComponentGeoLite2