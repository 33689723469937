import { useEffect, useState } from 'react'

function ComponentPixelRatio() {
	const [pixelRatio, setPixelRatio] = useState()

	useEffect(() => {
		setPixelRatio(window.devicePixelRatio === undefined ? 1 : window.devicePixelRatio)
	}, [])

	if(!pixelRatio) return null

	return (<div><div>
		<h2>PIXEL RATIO</h2><p>{pixelRatio}:1</p>
	</div></div>)
}

export default ComponentPixelRatio