import React, { useEffect, useState, useRef } from 'react'

import './index.scss'

import UiButton from '../../../ui/button'
import { clipboardOpen, Clipboard } from '../../../api'

function ClipboardComponent(props) {
	const { turnOn } = props

	const text = ''
	const [status, setStatus] = useState(false)
	const [clearSuccess, setClearSuccess] = useState(false)
	const [copySuccess, setCopySuccess] = useState({status: ''})
	const textAreaRef = useRef(null)

	useEffect(() => {

		clipboardOpen.on((item) => {
			setStatus(item)
		})

		Clipboard.on((text) => {
			textAreaRef.current.innerText = text

			let upText = !!text.length
			if(clearSuccess && !upText) setClearSuccess(upText)
			if(!clearSuccess && upText) setClearSuccess(upText)
		})

		return () => {
			Clipboard.off()
			clipboardOpen.off()
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const copyToClipboard = () => {
		navigator.clipboard.writeText(textAreaRef.current.innerText)
			.then(
				() => setCopySuccess({status: 'ok'}),
				() => setCopySuccess({status: 'error'})
			)

		setTimeout(() => {
			setCopySuccess({status: ''})
		}, 2000)
	}

	const clearToClipboard = () => {
		textAreaRef.current.innerText = ''
		setClearSuccess(false)
		Clipboard.emit('')
	}

	const onToClipboard = (event) => {
		const text = event.currentTarget.innerText

		Clipboard.emit(text)

		const statusText = !!text.length
		if(clearSuccess && !statusText) setClearSuccess(statusText)
		if(!clearSuccess && statusText) setClearSuccess(statusText)
	}

	if(!turnOn || !status) return false

	return (<div className='сlipboard'>
		<div>
			<div>

					<span>
						<span>
								<h1>
										Clipboard
								</h1>
						</span>
						<span>
								{
									clearSuccess && <UiButton
										skin='mini'
										type='button'
										text='Очистить'
										handleClick={clearToClipboard}
									/>
								}
							{
								clearSuccess && copySuccess.status === '' && <UiButton
									skin='mini'
									type='button'
									text='Скопировать'
									handleClick={copyToClipboard}
								/>
							}
						</span>
				</span>

				<div className={copySuccess.status === 'ok' ? 'copy' : copySuccess.status === 'error' ? 'error' : ''}>
					<div>
						<div
							ref={textAreaRef}
							contentEditable
							suppressContentEditableWarning
							onInput={e => onToClipboard(e)}
						>
							{text}
						</div>
					</div>
				</div>

			</div>
		</div>

	</div>)
}

export default ClipboardComponent