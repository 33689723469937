import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'

import { dataLenses, setLenses } from '../../../store/lenses'
import { arrMonths, formatDate, declination } from '../../../config'
import { Lenses } from '../../../api'

function InfoLenses(props) {
	const { turnOn } = props

	const lenses = useSelector(dataLenses)
	const dispatch = useDispatch()

	useEffect(() => {
		Lenses.emit()

		Lenses.on(data => {
			dispatch(setLenses(data))
		})

		return () => {
			Lenses.off()
		}
	}, [dispatch])

	if(!turnOn) return false

	const { day, date } = lenses

	if(!day) return null;

	let dayTime = +day + 1
	let dateStart = formatDate(date)

	return (<div className='info-lenses'>
		<div>
			<h4>От {dateStart.day} {arrMonths[dateStart.month]} {dateStart.year}</h4>
			<h1>{dayTime}</h1>
			<h3>{declination(dayTime, ['дней','день','дня'])}</h3>
		</div>
	</div>)
}

export default InfoLenses