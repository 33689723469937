import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Page from './pages'

import { formLogin, socketConnected } from './api'
import { setLogin } from './store/authorization'
import NoConnect from './pages/no_connect'
import { fullYear } from './config'

function Snowflakes() {

    // Вычисляем последнии 26 дней года и 15 дней начала года
    let uno1 = new Date(fullYear, 11, 5).getTime()
    let dos1 = new Date(fullYear, 11, 31).getTime()

    let uno2 = new Date(fullYear, 0, 1).getTime()
    let dos2 = new Date(fullYear, 0, 15).getTime()

    let tres = new Date().getTime()

    if((tres > uno1 && tres < dos1) || (tres > uno2 && tres < dos2)) {

        let snowflakes = []
        for (let i = 0; i < 30; i++) {
            snowflakes.push(<i key={i}/>)
        }

        return <div className="snowflakes">
            {snowflakes.map(el => el)}
        </div>

    } else return null
}

function App() {
    const dispatch = useDispatch()
    const [connect, setConnect] = useState(-1)

    useEffect(() => {
        formLogin.on(data => {
            dispatch(setLogin(data))
        })

        return () => {
            formLogin.off()
        }
    }, [dispatch])

    useEffect(() => {
        const timer = setInterval(() => {
            let status = socketConnected()
            if(!status) setConnect(connect + 1)
            if(status && connect) setConnect(0)
        }, connect === -1 ? 0 : 1000)
        return () => clearInterval(timer)
    }, [connect])

    if(connect > 9) return <>
        <Snowflakes/>
        <NoConnect connect={connect} />
    </>

    return <>
        <Snowflakes/>
        <Page/>
    </>
}

export default App
