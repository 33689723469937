import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'

import { Admin_a, Admin_b, Admin_c, Admin_d, Admin_i, Admin_f } from '../../api'
import { infoA, infoB, infoC, infoD, infoI, infoF, setInfoA, setInfoB, setInfoC, setInfoD, setInfoI, setInfoF } from '../../store/info_server'

import { styleFormat, readableBytes } from '../../config'

function Component1({mhz, cpu}) {
	if(!mhz || !cpu) return null

	const warn_1 = mhz > 3300
	const warn_2 = cpu > 55

	return (<span
		style={{order: warn_1 || warn_2 ? 1 : 101}}
	>

		<b>CPU</b>

		<strong className={warn_1 ? 'red' : null}>
			mhz: <span>{String(mhz).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')}</span>
		</strong>

		<strong className={warn_2 ? 'red' : null}>
			temp: <span>{cpu}&deg;C</span>
		</strong>

	</span>)
}

function Component2({free, available}) {
	if(!free || !available) return null

	const warn_1 = Number(free) > 50
	const warn_2 = Number(available) > 4000

	return (<span
		style={{order: warn_1 || warn_2 ? 102 : 1}}
	>

		<b>RAM</b>

		<strong className={warn_1 ? null : 'red'}>
			free: <span>{readableBytes(free, 'mb')}</span>
		</strong>

		<strong className={warn_2 ? null : 'red'}>
			available: <span>{readableBytes(available, 'mb')}</span>
		</strong>

	</span>)
}

function Component3({temp, status}) {
	if(!temp || !status) return null

	const warn_1 = temp > 45
	const warn_2 = status !== 'PASSED'

	return (<span
		style={{order: warn_1 || warn_2 ? 1 : 103}}
	>

		<b>HDD</b>

		<strong className={warn_1 ? 'red' : null}>
			temp: <span>{temp}&deg;C</span>
		</strong>

		<strong className={warn_2 ? 'red' : null}>
			status: <span>{status}</span>
		</strong>

	</span>)
}

function Component4({cpu, memory, restart}) {
	if(cpu === undefined || (!cpu && cpu !== 0) || !memory) return null

	const warn_1 = cpu > 100
	const warn_2 = memory > 12e8
	const warn_3 = restart > 10

	return (<span
		style={{order: warn_1 || warn_2 || warn_3 ? 1 : 104}}
	>

		<b>SOCKET</b>

		<strong className={warn_1 ? 'red' : null}>
			cpu: <span>{styleFormat(String(cpu))}%</span>
		</strong>

		<strong className={warn_2 ? 'red' : null}>
			memory: <span>{readableBytes(memory,'b')}</span>
		</strong>

		{restart ? <strong className={warn_3 ? 'red' : null}>
			restart: <span>{restart}</span>
		</strong> : null}

	</span>)
}

function Component5({balance}) {
	if(!balance) return null

	return (<span
		style={{order: 105}}
	>

			<b>SMS</b>

			<strong>balance: <span>{styleFormat(String(balance))} ₽</span></strong>

	</span>)
}

function Component6({ip}) {
	if(!ip) return null

	const warn_1 = ip !== '10.0.0.0'

	return (<span
		style={{order: warn_1 ? 1 : 106}}
	>

		<b>SSH</b>

		<strong className={warn_1 ? 'red' : null}>
			{ip !== '10.0.0.0' ? 'ip' : 'port'}: <span>{ip !== '10.0.0.0' ? ip : 'closed'}</span>
		</strong>

	</span>)
}

function AdminTop(props) {
	const { top } = props

	const dispatch = useDispatch()

	const info_a = useSelector(infoA)
	const info_b = useSelector(infoB)
	const info_c = useSelector(infoC)
	const info_d = useSelector(infoD)
	const info_i = useSelector(infoI)
	const info_f = useSelector(infoF)

	useEffect(() => {

		Admin_a.on(data => {
			dispatch(setInfoA(data))
		})
		Admin_b.on(data => {
			dispatch(setInfoB(data))
		})
		Admin_c.on(data => {
			dispatch(setInfoC(data))
		})
		Admin_d.on(data => {
			dispatch(setInfoD(data))
		})
		Admin_i.on(data => {
			dispatch(setInfoI(data))
		})
		Admin_f.on(data => {
			dispatch(setInfoF(data))
		})

		return () => {
			Admin_a.off()
			Admin_b.off()
			Admin_c.off()
			Admin_d.off()
			Admin_i.off()
			Admin_f.off()
		}
	}, [dispatch])

	if(+top !== 2) return null

	return (<div className='admin-top'>
		<div>
			{/* CPU */}
 			<Component1 mhz={info_a.mhz} cpu={info_a.cpu} />
			{/* RAM */}
			<Component2 free={info_b.free} available={info_b.available} />
			{/* HDD */}
			<Component3 temp={info_c.temp} status={info_c.status} />
			{/* SOCKET */}
			<Component4 cpu={info_d.cpu} memory={info_d.memory} restart={info_d.restart} />
			{/* SMS */}
			<Component5 balance={info_i} />
			{/* SSH */}
			<Component6 ip={info_f} />
		</div>
	</div>)
}

export default AdminTop
