import React, { useEffect, useState } from 'react'
import { YMaps, Map, Placemark, Circle, FullscreenControl, TypeSelector } from 'react-yandex-maps'

import './index.scss'

import { useDispatch, useSelector } from 'react-redux'
import { Admin_auto, GeoLite2Inquiry } from '../../../api'
import { GeoLite2, setGeoLite2, XForwardedFor } from '../../../store/user-information'
import { userIP, geoHtml } from '../../../store/authorization'
import {
	infoAuto,
	setInfoAuto
} from '../../../store/info_server'
import {getNumberOfSeconds} from "../../../config";

/*
			'Время последнего обновления: ' + new Date(json[auto].ts * 1000) + '\r\n' +
			'Скорость: ' + json[auto].v + ' км/ч\r\n' +
			'Движение: ' + json[auto].mv + '\r\n' +
			'На связи: ' + json[auto].on + '\r\n' +
			'Состояние GSM: ' + json[auto].gsm + ' dB\r\n' +
			'Состояние GPS: ' + autofon_gps[json[auto].gps] + '\r\n' +
			'Колличество спутников: ' + json[auto].sat + '\r\n' +
			'----------------------------\r\n' +
			'Состояние основного питания: ' + autofon_power[json[auto].pwr] + '\r\n' +
			'Напряжение внешннего источника: ' + (json[auto].epwr / 1000).toFixed(2) + ' B\r\n' +
			'Напряжение в батарейках : ' + (json[auto].ipwr / 1000).toFixed(2) + ' B\r\n' +
			'----------------------------\r\n' +
			'Время последнего обновления координат: ' + new Date(json[auto].tscrd * 1000) + '\r\n' +
			'lat: ' + json[auto].lat + '\r\n' +
			'lng: ' + json[auto].lng + '\r\n' +
			'Азимут направления: ' + json[auto].az + '°\r\n' +
			'Точность: ' + json[auto].precision + ' м\r\n' +
			'----------------------------\r\n' +
			'Средняя скорость: ' + json[auto].avgSpeed + ' км/ч\r\n' +
			'Максимальная скорость: ' + json[auto].maxSpeed + ' км/ч\r\n' +
			'Пробег: ' + (json[auto].mileage).toFixed(1) + ' км\r\n' +
			'----------------------------\r\n' +
			'Температура: ' + json[auto].tmp + '°C\r\n' +
			'----------------------------\r\n' + '\r\n' +
			'<img src="https://static-maps.yandex.ru/1.x/?pt=' + json[auto].lng + ',' + json[auto].lat + ',pm2rdm&z=17&size=250,450&l=map" alt="' + new Date(json[auto].tscrd * 1000) + '" />'
			//body
 */


function Info(props) {
	const { country, city, ipAddress, latitude, longitude, accuracyRadius, geo, geo_html, infoWarn, infoWarnQuantity } = props

	// 3902 - moto
	// 7719 - auto

	if(infoWarn) return <>
		<h1>
			<span>Скорость:</span> {infoWarn.v > 470 ? 'X' : infoWarn.v} км/ч<br/>
			<span>Средняя:</span> {infoWarn.avgSpeed} км/ч<br/>
			<span>Макс.:</span> {infoWarn.maxSpeed} км/ч<br/>
			<span>GSM:</span> {infoWarn.gsm} dB<br/>
			<span>GPS:</span> {infoWarn.gps}<br/>
			<span>Cпутников:</span> {infoWarn.sat}<br/>
			<span>Направления:</span> {infoWarn.az}°<br/>
			<span>Точность:</span> {infoWarn.precision} м<br/>
			<span>Пробег:</span> {infoWarn.mileage.toFixed(2)} км<br/>
			<span>Температура:</span> {infoWarn.tmp}°C
		</h1>
		<h2>
			<i className={infoWarn.id === 3902 ? 'moto' : 'auto'} />
			{infoWarnQuantity > 1 ? <i className={infoWarn.id === 3902 ? 'auto' : 'moto'} /> : null}
			<span>На связи:</span> {infoWarn.on ? 'Online' : 'Offline'}<br/>
		</h2>
	</>

	return <h1 className={country ? 'geo-map-www' : 'geo-map-home'}>
		{country && <>{country}<br/></>}
		{city ? <>
			<b>{city}</b>
			<br/><span>IP</span>: {ipAddress}
		</> : ipAddress && <>
			<b>IP: {ipAddress}</b>
		</>}
		{latitude && longitude && <>
			<br/>{latitude}°, {longitude}°
		</>}
		{accuracyRadius && <><br/><span>Радиус</span>: {accuracyRadius} км.</>}

		{geo && geo_html ? <><br/><br/></> : null}

		{geo_html ? <><span>Geolocation</span><br/>{geo_html.latitude.toFixed(4)}°, {geo_html.longitude.toFixed(4)}°
			{geo_html.accuracy && <><br/><span>Радиус</span>: {Math.floor(geo_html.accuracy)} м.</>}
		</>: null}
	</h1>
}
function GeoMapAuto(props) {
	const { geo_auto } = props

	if(!geo_auto) return null

	return (<>
		{geo_auto.map((el,i) => <Circle
			key={`circle_${i}`}
			geometry={[[+el.lat, +el.lng], el.precision]}
			options={{
				draggable: false,
				fillColor: el.on ? el.mv ? 'rgba(255,220,66,0.07)' : 'rgba(39,208,27,0.07)' : 'rgba(255,0,0,0.07)',
				strokeColor: el.on ? el.mv ? '#ffdc42' : '#27d01b' : '#F00',
				strokeOpacity: 0.2,
				strokeWidth: 2,
			}}
		/>)}

		{geo_auto.map((el,i) => <Placemark
			key={`place-mark-${i}`}
			geometry={[+el.lat, +el.lng]}
			properties={{
				iconContent: el.on && el.v < 250 ? `${el.v} км/ч` : i === 0 ? 'Мотоцикл' : 'Машина',
			}}
			options={{
				preset: el.on ? el.mv ? 'islands#yellowStretchyIcon' : 'islands#darkGreenStretchyIcon' : 'islands#redStretchyIcon'
			}}
		/>)}
	</>)
}

function GeoMapHTML(props) {
	const { geo_html } = props

	if(!geo_html) return null

	return (<>
		<Circle
			geometry={[[+geo_html.latitude, +geo_html.longitude], geo_html.accuracy]}
			options={{
				draggable: false,
				fillColor: 'rgba(78,134,254,0.07)',
				strokeColor: '#4e80fe',
				strokeOpacity: 0.4,
				strokeWidth: 2,
			}}
		/>
		<Placemark
			geometry={[+geo_html.latitude, +geo_html.longitude]}
			properties={{
				//balloonContentHeader: '1',
				//balloonContentBody: '2',
				//balloonContentFooter: '3',
				//iconCaption: 'Тут',
				//hintContent: '5',
				//balloonContent: '6',
				//iconContent: `7`
			}}
			options={{
				preset: 'islands#blueDotIcon'
			}}
		/>
	</>)
}
function GeoMapIP(props) {
	const { latitude, longitude, accuracyRadius, ipAddress } = props

	if(!latitude && !longitude) return null

	return (<>
		<Circle
			geometry={[[+latitude, +longitude], 1000 * accuracyRadius]}
			options={{
				draggable: false,
				fillColor: 'rgba(78,147,254,.0)',
				strokeColor: '#3f3f3f',
				strokeOpacity: 1,
				strokeWidth: 3,
			}}
		/>
		<Placemark
			geometry={[+latitude, +longitude]}
			properties={{
				//hintContent: '',
				//balloonContent: '',
				iconContent: ipAddress
			}}
			options={{
				preset: 'islands#blackStretchyIcon'
			}}
		/>
	</>)
}

function GeoMap(props) {
	const { turnOn } = props

	const ip = useSelector(userIP)
	const ip2 = useSelector(XForwardedFor)
	const geo = useSelector(GeoLite2)
	const geo_html = useSelector(geoHtml)
	const geo_auto = useSelector(infoAuto)
	const dispatch = useDispatch()

	const [flag, setFlag2] = useState()
	const [imgYes, setImgYes] = useState()

	const [country, setCountry] = useState()
	const [city, setCity] = useState()
	const [ipAddress, setIpAddress] = useState()
	const [latitude, setLatitude] = useState()
	const [longitude, setLongitude] = useState()
	const [accuracyRadius, setAccuracyRadius] = useState()
	const [mapCenter, setMapCenter] = useState([55.751999, 37.617734]) // 55.751999,37.617734 - Кремль
	const [mapCenterHtml, setMapCenterHtml] = useState(null)

	const [mapCenterWarn, setMapCenterWarn] = useState(null)
	const [infoWarn, setInfoWarn] = useState({obj: null, quantity: null})

	useEffect(() => {
		if(geo_html) setMapCenterHtml([+geo_html.latitude, +geo_html.longitude])
	}, [geo_html])

	useEffect(() => {
		if(geo_auto) {
			// Получаем активные датчики
			const online = geo_auto.filter(el => !!el.on)

			// Есть движение
			if(!!online.length) {
				let fresh = 0

				// Движение более 1 датчика (Пока их 2, насчитано на два датчика)
				if(online.length > 1) {
					const dateComparison = getNumberOfSeconds(new Date(online[0].ts * 1000), new Date(online[1].ts * 1000))
					fresh = dateComparison > 0 ? 1 : 0
				}

				// Записываем данные
				setMapCenterWarn([+online[fresh].lat, +online[fresh].lng])
				setInfoWarn({
					obj: online[fresh],
					quantity: online.length
				})

			}
			else {
				// Всё спокойно ;-)
				setMapCenterWarn(null)
				setInfoWarn({obj: null, quantity: null})
			}
		}
	}, [geo_auto])

	useEffect(() => {
		if(ip || ip2) GeoLite2Inquiry.emit(ip || ip2)

		GeoLite2Inquiry.on(data => {
			dispatch(setGeoLite2(data))
		})

		return () => {
			GeoLite2Inquiry.off()
		}
	}, [dispatch, ip, ip2])

	useEffect(() => {
		if(geo) {
			let json = JSON.parse(geo);

			let jsonCountry = 'country' in json ? json.country : false;
			let jsonCity = 'city' in json ? json.city : false;
			let jsonTraits = 'traits' in json ? json.traits : false;
			let jsonLocation = 'location' in json ? json.location : false;

			let x_country = jsonCountry ? 'names' in jsonCountry ? jsonCountry.names.en : null : null
			setCountry(x_country)
			setFlag2(x_country)
			setCity(jsonCity ? 'names' in jsonCity ? jsonCity.names.en : null : null)
			setIpAddress(jsonTraits ? 'ipAddress' in jsonTraits ? jsonTraits.ipAddress : null : null)
			setLatitude(jsonLocation ? 'latitude' in jsonLocation ? jsonLocation.latitude : null : null)
			setLongitude(jsonLocation ? 'longitude' in jsonLocation ? jsonLocation.longitude : null : null)
			setAccuracyRadius(jsonLocation ? 'accuracyRadius' in jsonLocation ? jsonLocation.accuracyRadius : null : null)
			setMapCenter([+jsonLocation.latitude, +jsonLocation.longitude])
		}
	}, [geo])

	useEffect(() => {
		if(flag) {
			let img = new Image()
			img.src = '/media/flag/' + flag + '.png'
			img.onload = () => setImgYes(true)
			img.onerror = () => setImgYes(false)
		}
	}, [flag])

	useEffect(() => {
		Admin_auto.on(data => {
			dispatch(setInfoAuto(data))
		})

		return () => {
			Admin_auto.off()
		}
	}, [dispatch])

	if(!turnOn) return null

	if(!geo && !geo_html) return null

	return (<div className='geo-map'>
		<div className='big'>
			<div>
				{imgYes && !infoWarn.obj ? <span><img src={`https://bf5.ru/media/flag/${flag}.png`} alt={flag} /></span> : null}

				<Info
					country={country}
					city={city}
					ipAddress={ipAddress}
					latitude={latitude}
					longitude={longitude}
					accuracyRadius={accuracyRadius}
					geo={geo}
					geo_html={geo_html}
					infoWarn={infoWarn.obj}
					infoWarnQuantity={infoWarn.quantity}
				/>

				<YMaps>
					<Map className='geo-map-yandex' state={{
						type: 'yandex#map',
						center: mapCenterWarn ? mapCenterWarn : mapCenterHtml ? mapCenterHtml : mapCenter,
						controls: [],
						zoom: infoWarn.obj ? 16 :
							geo_html ? 16
							:
							accuracyRadius ?
								accuracyRadius >= 2340 ? 1 :
									accuracyRadius >= 1140 ? 2 :
										accuracyRadius >= 540 ? 3 :
											accuracyRadius >= 240 ? 4 :
												accuracyRadius >= 140 ? 5 :
													accuracyRadius >= 80 ? 6 :
														accuracyRadius >= 40 ? 7 :
															accuracyRadius >= 20 ? 8 :
																accuracyRadius >= 15 ? 9 :
																	accuracyRadius >= 5 ? 10 :
																		accuracyRadius >= 3 ? 11 :
																			accuracyRadius >= 2 ? 12 :
																				accuracyRadius >= 1 ? 13 : 16
								: 16
					}}
					>

						<GeoMapIP latitude={latitude} longitude={longitude} accuracyRadius={accuracyRadius} ipAddress={ipAddress} />

						<GeoMapHTML geo_html={geo_html} />
						<GeoMapAuto geo_auto={geo_auto} />

						<FullscreenControl options={{ float: 'right' }} />
						<TypeSelector options={{ float: 'right' }} />
					</Map>
				</YMaps>
			</div>
		</div>
	</div>)

}

export default React.memo(GeoMap)