import { useEffect } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import './index.scss'

import { isInRange } from '../../../config'
import { WeatherAPI } from '../../../api'
import { setWeather, dataWeather } from '../../../store/weather'

const imgCode_dey = {
	"113": "skc_d", //"Ясно"
	"116": "bkn_d", //"Переменная облачность",
	"119": "bkn_d", //"Облачно",
	"122": "ovc", //"Пасмурно",
	"143": "ovc", //"Дымка",
	"176": "bkn_-ra_d", //"Местами дождь",
	"179": "bkn_-sn_d", //"Местами снег",
	"182": "ovc_ra_sn", //"Местами дождь со снегом",
	"185": "ovc_ra_sn", //"Местами замерзающая морось",
	"200": "bkn_ra_d", //"Местами грозы",
	"227": "bkn_-sn_d", //"Поземок",
	"230": "ovc_+sn", //"Метель",
	"248": "ovc", //"Туман",
	"260": "ovc", // "Переохлажденный туман",
	"263": "ovc_-ra", //"Местами слабая морось",
	"266": "ovc_-ra", //"Слабая морось",
	"281": "ovc_ra_sn", //"Замерзающая морось",
	"284": "ovc_ra_sn", //"Сильная замерзающая морось",
	"293": "ovc_-ra", //"Местами небольшой дождь",
	"296": "ovc_-ra", //"Небольшой дождь",
	"299": "ovc_ra", //"Временами умеренный дождь",
	"302": "ovc_ra", //"Умеренный дождь",
	"305": "ovc_+ra", //"Временами сильный дождь",
	"308": "ovc_+ra", //"Сильный дождь",
	"311": "ovc_+ra", //"Слабый переохлажденный дождь",
	"314": "ovc_+ra", //"Умеренный или сильный переохлажденный дождь",
	"317": "ovc_ra_sn", //"Небольшой дождь со снегом",
	"320": "ovc_ra_sn", //"Умеренный или сильный дождь со снегом",
	"323": "ovc_-sn", //"Местами небольшой снег",
	"326": "ovc_-sn", //"Небольшой снег",
	"329": "ovc_sn", //"Местами умеренный снег",
	"332": "ovc_sn", //"Умеренный снег",
	"335": "ovc_+sn", //"Местами сильный снег",
	"338": "ovc_+sn", //"Сильный снег",
	"350": "ovc_ra_sn", //"Ледяной дождь",
	"353": "ovc_+ra", //"Небольшой ливневый дождь",
	"356": "ovc_+ra", //"Умеренный или сильный ливневый дождь",
	"359": "ovc_+ra", //"Очень сильный ливень",
	"362": "ovc_ra_sn", //"Небольшой ливневый дождь со снегом",
	"365": "ovc_-sn", //"Небольшой снег",
	"368": "ovc_ra_sn", //"Небольшой ливневый дождь со снегом",
	"371": "ovc_+ra", //"Умеренный или сильный снежный ливень",
	"386": "ovc_ra", //"Местами небольшой дождь с грозой",
	"389": "ovc_+ra", //"Умеренный или сильный дождь с грозой",
	"392": "ovc_sn", //"Местами небольшой снег с грозой",
	"395": "ovc_+sn", //"Умеренный или сильный снег с грозой"
}

const imgCode_night = {
	"113": "skc_n", //"Ясно"
	"116": "bkn_n", //"Переменная облачность",
	"119": "bkn_n", //"Облачно",
	"122": "ovc", //"Пасмурно",
	"143": "ovc", //"Дымка",
	"176": "bkn_-ra_n", //"Местами дождь",
	"179": "bkn_-sn_n", //"Местами снег",
	"182": "ovc_ra_sn", //"Местами дождь со снегом",
	"185": "ovc_ra_sn", //"Местами замерзающая морось",
	"200": "bkn_ra_n", //"Местами грозы",
	"227": "bkn_-sn_n", //"Поземок",
	"230": "ovc_+sn", //"Метель",
	"248": "ovc", //"Туман",
	"260": "ovc", // "Переохлажденный туман",
	"263": "ovc_-ra", //"Местами слабая морось",
	"266": "ovc_-ra", //"Слабая морось",
	"281": "ovc_ra_sn", //"Замерзающая морось",
	"284": "ovc_ra_sn", //"Сильная замерзающая морось",
	"293": "ovc_-ra", //"Местами небольшой дождь",
	"296": "ovc_-ra", //"Небольшой дождь",
	"299": "ovc_ra", //"Временами умеренный дождь",
	"302": "ovc_ra", //"Умеренный дождь",
	"305": "ovc_+ra", //"Временами сильный дождь",
	"308": "ovc_+ra", //"Сильный дождь",
	"311": "ovc_+ra", //"Слабый переохлажденный дождь",
	"314": "ovc_+ra", //"Умеренный или сильный переохлажденный дождь",
	"317": "ovc_ra_sn", //"Небольшой дождь со снегом",
	"320": "ovc_ra_sn", //"Умеренный или сильный дождь со снегом",
	"323": "ovc_-sn", //"Местами небольшой снег",
	"326": "ovc_-sn", //"Небольшой снег",
	"329": "ovc_sn", //"Местами умеренный снег",
	"332": "ovc_sn", //"Умеренный снег",
	"335": "ovc_+sn", //"Местами сильный снег",
	"338": "ovc_+sn", //"Сильный снег",
	"350": "ovc_ra_sn", //"Ледяной дождь",
	"353": "ovc_+ra", //"Небольшой ливневый дождь",
	"356": "ovc_+ra", //"Умеренный или сильный ливневый дождь",
	"359": "ovc_+ra", //"Очень сильный ливень",
	"362": "ovc_ra_sn", //"Небольшой ливневый дождь со снегом",
	"365": "ovc_-sn", //"Небольшой снег",
	"368": "ovc_ra_sn", //"Небольшой ливневый дождь со снегом",
	"371": "ovc_+ra", //"Умеренный или сильный снежный ливень",
	"386": "ovc_ra", //"Местами небольшой дождь с грозой",
	"389": "ovc_+ra", //"Умеренный или сильный дождь с грозой",
	"392": "ovc_sn", //"Местами небольшой снег с грозой",
	"395": "ovc_+sn", //"Умеренный или сильный снег с грозой"
}

const imgCode_bg = {
	"176": "rain", //"Местами дождь",
	"179": "sleet", //"Местами снег",
	"182": "rain_sleet", //"Местами дождь со снегом",
	"185": "rain_sleet", //"Местами замерзающая морось",
	"227": "sleet", //"Поземок",
	"230": "sleet", //"Метель",
	"263": "rain", //"Местами слабая морось",
	"266": "rain", //"Слабая морось",
	"281": "rain_sleet", //"Замерзающая морось",
	"284": "rain_sleet", //"Сильная замерзающая морось",
	"293": "rain", //"Местами небольшой дождь",
	"296": "rain", //"Небольшой дождь",
	"299": "rain", //"Временами умеренный дождь",
	"302": "rain", //"Умеренный дождь",
	"305": "rain", //"Временами сильный дождь",
	"308": "rain", //"Сильный дождь",
	"311": "rain", //"Слабый переохлажденный дождь",
	"314": "rain", //"Умеренный или сильный переохлажденный дождь",
	"317": "rain_sleet", //"Небольшой дождь со снегом",
	"320": "rain_sleet", //"Умеренный или сильный дождь со снегом",
	"329": "sleet", //"Местами умеренный снег",
	"332": "sleet", //"Умеренный снег",
	"335": "sleet", //"Местами сильный снег",
	"338": "sleet", //"Сильный снег",
	"350": "rain_sleet", //"Ледяной дождь",
	"353": "rain", //"Небольшой ливневый дождь",
	"356": "rain", //"Умеренный или сильный ливневый дождь",
	"359": "rain", //"Очень сильный ливень",
	"362": "rain_sleet", //"Небольшой ливневый дождь со снегом",
	"365": "sleet", //"Небольшой снег",
	"368": "rain_sleet", //"Небольшой ливневый дождь со снегом",
	"371": "rain_sleet", //"Умеренный или сильный снежный ливень",
	"386": "rain", //"Местами небольшой дождь с грозой",
	"389": "rain", //"Умеренный или сильный дождь с грозой",
	"392": "rain_sleet", //"Местами небольшой снег с грозой",
	"395": "sleet", //"Умеренный или сильный снег с грозой"
};

const uv_text = {
	"1": "Низкий",
	"2": "Низкий",
	"3": "Умеренный",
	"4": "Умеренный",
	"5": "Умеренный",
	"6": "Высокий",
	"7": "Высокий",
	"8": "Очень высокий",
	"9": "Очень высокий",
	"10": "Очень высокий",
	"11": "Экстремальный",
}

function UV(props) {
	let { uv } = props
	if(!uv) return null

	return (<i> / {uv_text[uv]}</i>)
}

function InfoWeather(props) {
	const { turnOn } = props
	const json_weather = useSelector(dataWeather)
	const dispatch = useDispatch()

	useEffect(() => {
		WeatherAPI.emit()

		WeatherAPI.on(data => {
			dispatch(setWeather(data))
		})

		return () => {
			WeatherAPI.off()
		}
	}, [dispatch])

	if(!turnOn || !json_weather.weather) return false

	// День
	const pogoda_fon_d = '/weather/d.jpg'
	const pogoda_fon_d_0_5 = './weather/d_0_5.jpg'

	// Закат
	const pogoda_fon_sunset = './weather/sunset.jpg'

	// Ночь
	const pogoda_fon_n = '/weather/n.jpg'
	const pogoda_fon_n_0 = '/weather/n_0.jpg'
	const pogoda_fon_n_0_5 = '/weather/n_0_5.jpg'

	// Осадки
	const pogoda_fon_rain = '/weather/rain.svg'
	const pogoda_fon_rain_sleet = './weather/rain_sleet.svg'
	const pogoda_fon_sleet = '/weather/sleet.svg'

	const { country, city, latitude, longitude, weather, error} = json_weather

	if(error) return null

	const { temp, feels, condition, location, sun, wind, speed, humidity, pressure, cloudiness, uv } = weather

	if(!sun) return null

	const coordinates = <>{parseFloat(latitude).toFixed(3)}°, {parseFloat(longitude).toFixed(3)}°</>
	const icon = sun.daytime === 'DAY' ? imgCode_dey[condition.code] : imgCode_night[condition.code]

	let fon = sun.daytime === 'DAY' ?
		cloudiness < 75 ? isInRange(sun.sunset) ? pogoda_fon_sunset : pogoda_fon_d_0_5 : pogoda_fon_d :
		cloudiness < 10 ? isInRange(sun.sunset) ? pogoda_fon_sunset : pogoda_fon_n_0 : cloudiness > 51 ? pogoda_fon_n : pogoda_fon_n_0_5

	const fon_rain = imgCode_bg[condition.code] === 'rain_sleet' ? pogoda_fon_rain_sleet :
		imgCode_bg[condition.code] === 'rain' ? pogoda_fon_rain :
		imgCode_bg[condition.code] === 'sleet' ? pogoda_fon_sleet :
			'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='

	return (<div className='info-weather'>
		<div
			className='big'
			style={{
				backgroundImage: `url(${fon})`,
				backgroundColor: sun.daytime === 'DAY' ? '#618ab6' : '#283b73',
				borderColor: sun.daytime === 'DAY' ? '#FFF' : '#5a6ca9'
			}}
		>
			<div style={{backgroundImage: `url(${fon_rain})`}}>

				<div>

					<h1><span>{country} </span> {city ? <>
							{city} <b>{location.weather_time}</b>
						<strong>{coordinates}</strong>
						</> : coordinates}
					</h1>

					<div className='info-weather-temp'>
						<div>
							{temp === 0 ? null : temp > 0 ? '+' : null}{temp}&deg;
						</div>
						<div>
							<img src={`https://hosting.bf5.ru/files/weather/${icon}.svg`} alt={condition.value} />
						</div>
						<div>
							<p><b>{condition.value}</b></p>
							<p>Ощущаемая температура: <b>{feels === 0 ? null : feels > 0 ? '+' : null}{feels}&deg;</b></p>
						</div>
					</div>

					<div className="info-weather-wind">Ветер: <b>{wind.value}</b> — <b>{speed} м/с.</b></div>

					<div className="info-weather-info">
						<div>
							<div>
								Влажность: <b>{humidity}%</b>
							</div>
							<div>
								Давление: <b>{pressure} мм.рт.ст.</b>
							</div>
						</div>
						<div>
							<div>
								Облачность: <b>{cloudiness}%</b>
							</div>
							<div>
								УФ индекс: <b>{uv} <UV uv={uv} /></b>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div className="info-weather-sun">
						<div>Восход: <b>{sun.sunrise}</b></div>
						<div>День: <b>{sun.longitude}</b></div>
						<div>Заход: <b>{sun.sunset}</b></div>
					</div>
				</div>

			</div>
		</div>
	</div>)
}

export default InfoWeather