import React, { useEffect, useState }  from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './index.scss'

import SettingsBookmark from '../settings_bookmark'

import {
	targetLink,
	activeCategory,
	bookmarksClick,
	allBookmarks,
	listBookmarks,
	setDragSortListBookmarks,
	setDragSave,
	dragSave,
	searchBookmarks
} from '../../store/bookmarks'
import { TileClick, TileSort } from '../../api'

const createMarkup = (html) => {
	return {__html: html}
}

function ImgBookmark(props) {
	//const { img, alt } = props
	const { img } = props

	const [picture, setPicture] = useState('/bookmarks/no_foto.jpg')

	useEffect(() => {
		if(!!img) {
			const srcPicture = String(img).substring(0, 5) === 'data:' ? img : `/bookmarks/${!!img ? img : 'no_foto.jpg'}`

			if(String(srcPicture).substring(0, 1) === '/') {

				// Проверим наличие картинки
				let img = new Image()
				img.src = srcPicture
				img.onload = () => setPicture(srcPicture)
				img.onerror = () => setPicture('https://bf5.ru/bookmarks/no_foto.jpg')

			}
			else setPicture(srcPicture)
		}
	}, [img])

	return <img src={picture} alt='' />
}

function Bookmark(props) {
	const { bookmark, show = true } = props

	const category = useSelector(activeCategory)
	const target = useSelector(targetLink)
	const bookmarks_click = useSelector(bookmarksClick)
	const all_bookmarks = useSelector(allBookmarks)
	const listStore = useSelector(listBookmarks)
	const correntBookmark = useSelector(dragSave)
	const search = useSelector(searchBookmarks)
	const dispatch = useDispatch()

	const [style, setStyle] = useState()
	const [title, setTitle] = useState()
	const [alt, setAlt] = useState()
	const [col, setCol] = useState(null)
	const [href, setHref] = useState()
	const [vip, setVip] = useState()

	useEffect(() => {
		if (bookmark.color || bookmark.color === '') {
			setStyle({
				background: (bookmark.color && bookmark.color !== '#000') ? bookmark.color.substring(0, 1) !== '#' ? `#${bookmark.color}` : bookmark.color : 'linear-gradient(-45deg, rgb(190, 64, 4), rgb(255, 255, 255) 80%)',
				color: bookmark.color ? bookmark.color.substring(0, 1) !== '#' ? `#${bookmark.color}` : bookmark.color : '#F26F31'
			})
		}

		if (bookmark.info || bookmark.info === '') {
			setTitle(bookmark.info ? <h1 dangerouslySetInnerHTML={createMarkup(bookmark.info)}/> : null)
		}

		if (bookmark.info || bookmark.url) {
			setAlt(bookmark.info ? bookmark.info : bookmark.url)
		}

		if(bookmark.vip) {
			setVip(bookmark.vip === '0' ? null : <i className={bookmark.vip} />)
		}

		if(bookmark.col && bookmarks_click) {
			setCol(bookmarks_click === '1' ? <span><span>Переходов: <b>{bookmark.col}</b></span></span> : null)
		}

		if(bookmark.url && show) {
			setHref(<a href={bookmark.url} onClick={(e) => click(e, target, bookmark.url, bookmark.id, category)}>{title}</a>)
		} else {
			setHref(null)
		}

		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookmark, show, bookmarks_click])

	const click = (e, target, url, id, cat) => {
		e.preventDefault()

		// Клик по маленьким кнопкам, редактирование и т.п.
		if(!!e.target.closest('.settings-bookmark')) return null

		TileClick.tileClick(id, cat, all_bookmarks)

		setTimeout(() => {
			window.open(url, target)
		}, target === '_blank' ? 0 : 100)
	}

	// DRAG
	const dragStartHandler = (e, bookmark) => {
		dispatch(setDragSave(bookmark))
	}

	const dragEndHandler = (e) => {
		e.target.style.opacity = 1
	}

	const dragOverHandler = (e) => {
		e.preventDefault()
		e.target.style.opacity = .2
	}

	const dragHandler = (e, bookmark) => {
		e.preventDefault()

		const dragList = listStore.map(b => {
			if(b.id === bookmark.id) {
				return {...b, nomer: correntBookmark.nomer}
			}
			if(b.id === correntBookmark.id) {
				return {...b, nomer: bookmark.nomer}
			}
			return b
		})

		const sortBookmark = (a, b) => a.nomer > b.nomer ? 1 : -1
		const newList = dragList.sort(sortBookmark)
		dispatch(setDragSortListBookmarks(newList))
		TileSort.limit(newList.map(el => el.id), all_bookmarks)

		e.target.style.opacity = 1
	}


	if(category === 'last' || !!search) {

		return (<div
			className='bookmark'
			style={style}
		>
			{vip}
			<ImgBookmark img={bookmark.img} alt={alt}/>
			{title}
			{col}
			{href}
			<SettingsBookmark show={show} cat={category} tip={bookmark.tip} id={bookmark.id}/>
		</div>)

	} else {

		return (<div
			className='bookmark'
			style={style}
			onDragStart={(e) => dragStartHandler(e, bookmark)}
			onDragLeave={(e) => dragEndHandler(e)}
			onDragEnd={(e) => dragEndHandler(e)}
			onDragOver={(e) => dragOverHandler(e)}
			onDrop={(e) => dragHandler(e, bookmark)}
			onClick={(e) => click(e, target, bookmark.url, bookmark.id, category)}
			draggable={true}
		>
			{vip}
			<ImgBookmark img={bookmark.img} alt={alt}/>
			{title}
			{col}
			<SettingsBookmark show={show} cat={category} tip={bookmark.tip} id={bookmark.id}/>
		</div>)

	}
}

export default Bookmark