import React from 'react'

// Меню
export const navMenuUser = [
	{value: '', name:'Нет категории'},
	{value: 'last', name: 'Недавно используемые', icon: 'navigation-nav-icon-last'},
	{value: 'work', name: 'Работа', icon: 'navigation-nav-icon-work'},
	{value: 'school', name: 'Школа', icon: 'navigation-nav-icon-school'},
	{value: 'money', name: 'Деньги', icon: 'navigation-nav-icon-money'},
	{value: 'news', name: 'Новости', icon: 'navigation-nav-icon-news'},
	{value: 'radio', name: 'Радиолюбитель', icon: 'navigation-nav-icon-radio'},
	{value: 'my_vip', name: 'VIP', icon: 'navigation-nav-icon-my_vip'},
	{value: 'avto', name: 'Автомобиль', icon: 'navigation-nav-icon-avto'},
	{value: 'moto', name: 'Мотоцикл', icon: 'navigation-nav-icon-moto'},
	{value: 'foto', name: 'Фотографии', icon: 'navigation-nav-icon-foto'},
	{value: 'home', name: 'Разные сайты', icon: 'navigation-nav-icon-home'},
	{value: 'info', name: 'Информация', icon: 'navigation-nav-icon-info'},
	{value: 'paket', name: 'Магазины', icon: 'navigation-nav-icon-paket'},
	{value: 'torrent', name: 'Кино, Музыка', icon: 'navigation-nav-icon-torrent'},
	{value: 'sport', name:'Спорт, Игры', icon: 'navigation-nav-icon-sport'},
	{value: 'video', name:'Видео, IPTV', icon: 'navigation-nav-icon-video'},
	{value: 'start', name:'Журналы', icon: 'navigation-nav-icon-start'},
	{value: 'ibox', name:'Коробка закладок', icon: 'navigation-nav-icon-ibox'}
]
export const navMenu = [
	{value: '', name:'Нет категории'},
	{value: 'last', name: 'Недавно используемые', icon: 'navigation-nav-icon-last'},
	{value: 'digital', name: 'Центр цифры', icon: 'navigation-nav-icon-digitalcenter', skin: 'digitalcenter'},
	{value: 'alean', name: 'Алеан', icon: 'navigation-nav-icon-alean', skin: 'alean'},
	{value: 'cse', name: 'Курьер Сервис Экспресс', icon: 'navigation-nav-icon-cse'},
	{value: 'nts', name: 'НефтеТрансСервис', icon: 'navigation-nav-icon-work-2'},
	{value: 'nvg', name: 'Энвижн Груп', icon: 'navigation-nav-icon-nvg'},
	{value: 'synergy', name: 'Синергия', icon: 'navigation-nav-icon-work-2'},
	{value: 'megafon', name: 'МегаФон', icon: 'navigation-nav-icon-megafon'},
	{value: 'work', name: 'Работа', icon: 'navigation-nav-icon-work'},
	{value: 'radio', name: 'Радиолюбитель', icon: 'navigation-nav-icon-radio'},
	{value: 'school', name: 'Школа', icon: 'navigation-nav-icon-school'},
	{value: 'money', name: 'Деньги', icon: 'navigation-nav-icon-money'},
	{value: 'news', name: 'Новости', icon: 'navigation-nav-icon-news'},
	{value: 'my_vip', name: 'VIP', icon: 'navigation-nav-icon-my_vip'},
	{value: 'avto', name: 'Автомобиль', icon: 'navigation-nav-icon-avto'},
	{value: 'moto', name: 'Мотоцикл', icon: 'navigation-nav-icon-moto'},
	{value: 'foto', name: 'Фотографии', icon: 'navigation-nav-icon-foto'},
	{value: 'home', name: 'Разные сайты', icon: 'navigation-nav-icon-home'},
	{value: 'info', name: 'Информация', icon: 'navigation-nav-icon-info'},
	{value: 'paket', name: 'Магазины', icon: 'navigation-nav-icon-paket'},
	{value: 'torrent', name: 'Кино, Музыка', icon: 'navigation-nav-icon-torrent'},
	{value: 'sport', name:'Спорт, Игры', icon: 'navigation-nav-icon-sport'},
	{value: 'video', name:'Видео, IPTV', icon: 'navigation-nav-icon-video'},
	{value: 'start', name:'Журналы', icon: 'navigation-nav-icon-start'},
	{value: 'ibox', name:'Коробка закладок', icon: 'navigation-nav-icon-ibox'},
	{value: 'xxl', name:'18+', icon: 'navigation-nav-icon-xxl'},
	{value: 'demo', name:'Demo', icon: 'navigation-nav-icon-demo'},
	{value: 'all', name:'Все закладки'}
]

// Получение названия категории
export const categoryName = (value, text) => {
	const obj = navMenu.filter(el => el.value === value)
	return obj.length ? <><b>Категория: </b> {obj[0].name}</> : <>{text} {value}</>
}

// Ленты на закладках
export const vipBookmark = [
	{name: 'Без ленты', value: '0'},
	{name: 'Красная лента', value: 'all'},
	{name: 'Жёлтая лента', value: 'vip'},
	{name: 'Зелёная лента', value: 'vi2'},
	{name: 'Синяя лента', value: 'news'}
]

// Округление данных
const readableBytesSizes = {
	b: ['B', 'KB', 'MB', 'GB', 'TB'],
	mb: ['MB', 'GB', 'TB']
}

export const readableBytes = (mb, s= 'b') => {
	let i = Math.floor(Math.log(mb) / Math.log(1024)),
		sizes = readableBytesSizes[s],
		number = (mb / Math.pow(1024, i)).toFixed(2),
		n = number.split('.')
	return (<>
		{n[1] && n[1] !== '00' ? (<>{n[0]},<span>{n[1]}</span></>) : n[0]} {sizes[i]}
	</>)
}

export const styleFormat = (number) => {
	let n = number.split('.')
	return n[1] && n[1] !== '00' ? (<>{n[0]},<span>{n[1]}</span></>) : n[0]
}


// Дни недели
export const arrWeekDaysMini = ['Вc','Пн','Вт','Ср','Чт','Пт','Сб','Вс']
export const arrWeekDays = ['Воскресенье','Понедельник','Вторник','Среда','Четверг','Пятница','Суббота','Воскресенье']

// Месяцы
export const arrMonthsCalendar = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
export const arrMonths = ['января','февраля','марта','апреля','майя','июня','июля','августа','сентября','октября','ноября','декабря']

// Форматирование даты
export const formatDate = (d, calendar = false) => {
	if(!d) return {week: null, day: null, month: null, year: null, hours: null, minutes: null}

	const convertDate = new Date(d),
		week = convertDate.getDay(), // День недели
		day = convertDate.getDate(), // День
		month = convertDate.getMonth(), // Месяц
		year = convertDate.getFullYear(), // Год
		hours = convertDate.getHours() || null, // Часы
		minutes = convertDate.getMinutes() || null // Минуты

	if(calendar) {
		let days = new Date(year, month + 1, 0).getDate(), // Количество дней в месяце
			first_week = new Date(year, month, 1).getDay() // Первый день недели месяца

		first_week = first_week === 0 ? 6 : first_week - 1

		return {week, day, month, year, days, hours, minutes, first_week}
	}

	return {week, day, month, year, hours, minutes}
}

// Количество секунд между датами
export const getNumberOfSeconds = (start, end) => {
	const date1 = new Date(start),
		date2 = new Date(end),
		oneSeconds = 1000,
		diffInTime = date2.getTime() - date1.getTime()
	return Math.round(diffInTime / oneSeconds)
}

// Количество дней между датами
export const getNumberOfDays = (start, end) => {
	const date1 = new Date(start),
		date2 = new Date(end),
		oneDay = 1000 * 60 * 60 * 24,
		diffInTime = date2.getTime() - date1.getTime()
	return Math.round(diffInTime / oneDay)
}

// Текущий год
export const fullYear = new Date().getFullYear()

// Склонение
export const declination = (s, arr) => {
	const sk = arr
	let i = s % 100

	if (i >=11 && i <= 14){i = 0}
	else {i = (i %= 10) < 5 ? (i > 2 ? 2 : i): 0}

	const ii = Math.floor(i)
	return sk[ii]
}

// Версия React
export const rect_version = <>React v{React.version.split('-')[0]}</>

// Копирайт
export const copyright = <>© 2011 - {fullYear} <a rel="noreferrer" target='_blank' href='https://shustrov.pro'>SHUSTROV.PRO</a></>


// Проверка времени (Проверка заката)
export const isInRange = (sunset) => {
	const now = new Date()
	const day = formatDate(now)
	const s = sunset.split(':')

	const sun = new Date(day.year, day.month, day.day, +s[0], +s[1], 0)

	const start = new Date((sun.getTime() - 1000 * 60 * 20))
	const end = new Date((sun.getTime() + 1000 * 60 * 20))

	return start <= now && now <= end
}
