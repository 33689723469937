import './index.scss'

import { copyright, rect_version } from '../../config'

function Footer() {

	return (<div className='footer'>
		<div>
			{copyright}
		</div>
		<div>
			<a rel="noopener noreferrer" target="_blank" href="https://hosting.bf5.ru/">ХОСТИНГ <b>BF5.RU</b></a>
		</div>
		<div>
			{rect_version}
		</div>
	</div>)
}

export default Footer