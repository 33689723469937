import { createSlice } from '@reduxjs/toolkit'

import { navMenu } from '../config'

export const Bookmarks = createSlice({
	name: "bookmarks",
	initialState: {
		bookmarks_all: 0, // Общее число закладок у пользлвателя
		basket_all: 0, // Закладок в корзине
		all: 0, // Количество закладок
		limit: 50, // Количество закладок
		list: [], // Массив закладок
		loading: {  // Загрузка ...
			status: true,
			change: '', // Поле, используется для loading по факту не нужно
			date: Date.now()
		},
		menu: [], // Меню
		active: null, // Активная категория
		target: '_blank', // target - как открывать закладки
		isearch: [], // Быстрый поиск
		lenta: {}, // Закладки "ленты"
		click: null, // Количество переходов, кликов по закладке
		add: 1,
		editBookmark: false, // Редактирование закладки
		openNav: false, // Открытие меню
		type: null, // Тип ответа массива с закладками
		search: '', // Поиск,
		color: '', // Цвет поиска,
		dragSave: {} // Запоминаем элемент сортировки
	},
	reducers: {
		setListBookmarks: (state, list) => {
			const json = JSON.parse(list.payload)

			if('type' in json && json.type !== 'DEMO') {

				state.list = 'bookmarks' in json ? !!json.start ? [...state.list, ...json.bookmarks] : [...json.bookmarks] : []

				state.search = 'search' in json ? json.search : ''
				state.color = 'color' in json ? json.color : state.color
				state.all = 'all' in json ? json.all : state.all
				state.active = 'active' in json ? json.active : state.active
				state.target = 'target' in json ? json.target : state.target
				state.isearch = 'isearch' in json ? json.isearch : state.isearch
				state.click = 'click' in json ? json.click : state.click
				state.add = 'add' in json ? json.add : state.add
				state.limit = 'limit' in json ? json.limit : state.limit
				state.type = 'type' in json ? json.type : state.type

				state.menu = json.menu ? json.menu.map(el => { // Дополняем меню названиями из конфига
					const obj = navMenu.find(nav => nav.value === el)
					return {
						value: el,
						name: obj ? obj.name : '- - -',
						icon: obj ? 'icon' in obj && obj.icon : false
					}
				}) : null

				state.loading = {
					status: false,
					date: Date.now()
				}
			}
		},
		setUpdateBookmark: (state, bookmark) => { // Изменения в одной закладке
			const book = JSON.parse(bookmark.payload)
			state.list = state.list.map(el => el.id === book.id ? book : el)
		},
		setEditBookmark: (state, bookmark) => { // Редактирование закладки
			const json = JSON.parse(bookmark.payload)
			state.editBookmark = json.result ? json.result : false
		},
		setOpenNav: (state) => { // Открытие / Закрытие меню
			state.openNav = !state.openNav
		},
		setSearchLenta: (state, lenta) => { // Открытие / Закрытие меню
			state.lenta = JSON.parse(lenta.payload)
		},
		setSearch: (state, search) => { // Открытие / Закрытие меню
			if(!!search.payload) {
				state.loading.status = true

				const json = JSON.parse(search.payload)
				state.search = json.search
				state.color = json.color === 'normal' ? '' : json.color
			}
		},
		setSearchColor: (state, color) => { // Открытие / Закрытие меню
			state.color = color.payload
		},
		setBookmarkSearch: (state, drag) => {
			state.loading.status = true
			state.loading.change = drag.payload
		},
		setDragSave: (state, drag) => {
			state.dragSave = drag.payload
		},
		setDragSortListBookmarks: (state, list) => {
			state.list = [...list.payload]
		},
		setBookmarksAll: (state, all) => {
			state.bookmarks_all = all.payload
		},
		setBasketAll: (state, all) => {
			state.basket_all = all.payload
		}
	}
})

export const {
	setListBookmarks,
	setUpdateBookmark,
	setEditBookmark,
	setOpenNav,
	setSearchLenta,
	setSearch,
	setDragSave,
	setDragSortListBookmarks,
	setBookmarksAll,
	setBasketAll,
	setSearchColor,
	setBookmarkSearch
} = Bookmarks.actions

export const listBookmarks = (state) => state.bookmarks.list
export const loadingBookmarks = (state) => state.bookmarks.loading.status
export const typeBookmarks = (state) => state.bookmarks.type
export const searchBookmarks = (state) => state.bookmarks.search
export const colorSearch = (state) => state.bookmarks.color
export const allBookmarks = (state) => state.bookmarks.all
export const activeCategory = (state) => state.bookmarks.active
export const targetLink = (state) => state.bookmarks.target
export const bookmarksClick = (state) => state.bookmarks.click
export const editBookmark = (state) => state.bookmarks.editBookmark
export const menuBookmark = (state) => state.bookmarks.menu
export const openNav = (state) => state.bookmarks.openNav
export const quickSearch = (state) => state.bookmarks.isearch
export const searchLenta = (state) => state.bookmarks.lenta
export const dragSave = (state) => state.bookmarks.dragSave
export const bookmarksAll = (state) => state.bookmarks.bookmarks_all
export const basketAll = (state) => state.bookmarks.basket_all

export default Bookmarks.reducer