import { createSlice } from '@reduxjs/toolkit'

export const Socket = createSlice({
	name: "lenses",
	initialState: {
		day: null,
		date: null
	},
	reducers: {
		setLenses: (state, data) => {
			const json = JSON.parse(data.payload)
			state.day = json.day
			state.date = json.date
		},
	}
})

export const {
	setLenses,
} = Socket.actions

export const dataLenses = (state) => {
	return {
		day: state.lenses.day,
		date: state.lenses.date
	}
}

export default Socket.reducer


