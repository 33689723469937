import io from 'socket.io-client'

const socket = io('https://bf5.ru', {
	withCredentials: true,
	path: '/ws'
})

// Создаём токен в браузере
function stringGen(len){
	let text = ''
	const charset = "abcdefghijklmnopqrstuvwxyz0123456789"
	for( let i=0; i < len; i++ )
		text += charset.charAt(Math.floor(Math.random() * charset.length))
	return text
}

let browserToken = localStorage.getItem('browser-token')
if (typeof browserToken !== 'string') {
	browserToken = [
		'bookmarks',
		(String(Math.random())).slice(2),
		new Date().getTime(),
		stringGen(7)
	].join('-')
	localStorage.setItem('browser-token', browserToken)
}

// Подключение к сокету и передаём токен и ключь
socket.on('connect', function() {
	let browserKey = localStorage.getItem('browser-key')
	socket.emit('setBrowserToken', browserToken, browserKey)
})

// browser-key для проверки на сервере
socket.on('browser-key', function(key) {
	localStorage.setItem('browser-key', key)
	if(localStorage.getItem('browser-key') === key) socket.emit('browser-key-reply', key)
})

// Проверка подключение к сокету
export function socketConnected() {
	return socket.connected;
}


class Socket {
	constructor(options) {
		this.name = options.name
		this.save = options.save
	}
	loginReg(user, password, salt){
		socket.emit('registration', user, password, salt)
	}
	login(user, password){
		socket.emit('authorization', user, password)
	}
	ready(){
		socket.emit('ready')
	}
	tileClick(id, cat, all_bookmarks){
		socket.emit(this.name, id, cat, all_bookmarks)
	}
	tileClickNull(id, all_bookmarks){
		socket.emit(this.name, id, all_bookmarks)
	}
	bookmarkEdit(id, name, value){
		socket.emit(this.name, id, name, value)
	}
	settingsEdit(name, value){
		socket.emit(this.name, name, value)
	}
	search(value, lenta = ''){
		socket.emit(this.name, value, lenta)
	}
	limit(start, limit){
		socket.emit(this.name, start, limit)
	}
	emit(send){
		socket.emit(this.name, send)
	}
	on(get){
		socket.on(this.name, data => get(data))
	}
	off(){
		socket.off(this.name)
	}
}

export const Ready = new Socket({name: 'ready'})
export const messagesInfo = new Socket({name: 'message'})
export const formLogin = new Socket({name: 'refreshAuth'})
export const ioFormLogin = new Socket({name: 'ioFormLogin'})
export const sendLogin = new Socket({name: 'authorization_token'})
export const ioNewToken = new Socket({name: 'new_token'})
export const ioLogout = new Socket({name: 'isLogout'})
export const ioNewLogin = new Socket({name: 'ioNewLogin'})
export const ioPageLogin = new Socket({name: 'ioPageLogin'})
export const Categories = new Socket({name: 'categories'})
export const TileUp = new Socket({name: 'tile_up'})
export const TileClick = new Socket({name: 'tile_click'})
export const BookmarkNoEdit = new Socket({name: 'bookmark_no_edit'})
export const BookmarkEditEmit = new Socket({name: 'bookmark'})
export const BookmarkReturnBook = new Socket({name: 'bookmark_return_book'})
export const BookmarkBook = new Socket({name: 'bookmark_book'})
export const TileNullClick = new Socket({name: 'tile_null_click'})
export const BookmarksAll = new Socket({name: 'bookmarks_all'})
export const BasketAll = new Socket({name: 'basket_all'})
export const BookmarkBasket = new Socket({name: 'bookmark_basket'})
export const BookmarkEditClose = new Socket({name: 'bookmarkClose'})
export const BookmarkEditID = new Socket({name: 'bookmark_edit'})
export const BookmarkSearch = new Socket({name: 'bookmark_search'})
export const BookmarkSearchValue = new Socket({name: 'search_value'})
export const CategoryChange = new Socket({name: 'cat_click_update'})
export const socketUsersEmit = new Socket({name: 'socketUsersStart'})
export const socketUsers = new Socket({name: 'socketUsers'})
export const SettingsEdit = new Socket({name: 'settings'})
export const SettingsUpdate = new Socket({name: 'settings_edit'})
export const ipAddress = new Socket({name: 'ip-address'})
export const BookmarkSearchLenta = new Socket({name: 'bookmark_lenta'})
export const TileSort = new Socket({name: 'tile_sort'})

export const WeatherAPI = new Socket({name: 'pogoda_api'})
export const tvShow = new Socket({name: 'tv_show'})
export const tvProgramme = new Socket({name: 'tv_programme'})
export const Lenses = new Socket({name: 'timer_day'})

export const Admin_a = new Socket({name: 'admin_info_a'})
export const Admin_b = new Socket({name: 'admin_info_b'})
export const Admin_c = new Socket({name: 'admin_info_c'})
export const Admin_d = new Socket({name: 'admin_info_d'})
export const Admin_i = new Socket({name: 'admin_info_i'})
export const Admin_f = new Socket({name: 'admin_info_f'})
export const Admin_auto = new Socket({name: 'admin_auto'})

export const clipboardOpen = new Socket({name: 'clipboard-open'})
export const Clipboard = new Socket({name: 'clipboard'})

export const yoIp2 = new Socket({name: 'x-forwarded-for'})
export const UserAgent = new Socket({name: 'user-agent'})
export const Info = new Socket({name: 'info'})
export const GeoLite2Inquiry = new Socket({name: 'geo-lite2'})
export const GeoLite2Org = new Socket({name: 'geo-organization'})


/*
function Categories(d) {
	socket.emit('categories');
	socket.on('categories', categories => d(null, categories));
}
export {Categories};

function Off_Categories() {
	socket.off('categories');
}
export {Off_Categories};


function CategoriesPlus(limit) {
	socket.emit('categories', limit);
}
export {CategoriesPlus};


 */

/*


// Подключение к socket
export const On_socketConnect = (t) => {
	socket.on('socket-connect', status => t(status));
}

export const Off_socketConnect = () => {
	socket.off('socket-connect');
}


function ioHome(d) {
	socket.on('local', home => d(null, home));
}
export {ioHome};

function Off_ioHome() {
	socket.off('local');
}
export {Off_ioHome};


function ioTimer(d) {
	socket.on('timer', timestamp => d(null, timestamp));
}
export {ioTimer};

function Off_ioTimer() {
	socket.off('timer');
}
export {Off_ioTimer};


function socket_users_emit() {
	socket.emit('socketUsersStart');
}
export {socket_users_emit};

function socket_users(d) {
	socket.on('socketUsers', users => d(null, users));
}
export {socket_users};

function Off_socket_users() {
	socket.off('socketUsers');
}
export {Off_socket_users};

// Push
function socket_push(d) {
	socket.on('socketPush', push => d(null, push));
}
export {socket_push};

function Off_socket_push() {
	socket.off('socketPush');
}
export {Off_socket_push};


// Обновление авторизованых клиентов
function formLogin(d) {
	socket.on('refreshAuth', login => d(null, login));
}
export {formLogin}

function Off_formLogin() {
	socket.off('refreshAuth');
}
export {Off_formLogin}



function ioLoginReg(user, password, salt) {
	socket.emit('registration', user, password, salt);
}
export {ioLoginReg};


function ioLogin(user, password) {
	socket.emit('authorization', user, password);
}
export {ioLogin};

function ioLoginToken(token) {
	socket.emit('authorization_token', token);
}
export {ioLoginToken};


function ioNewLogin() {
	socket.emit('ioNewLogin');
}
export {ioNewLogin};


function ioPageLogin() {
	socket.emit('ioPageLogin');
}
export {ioPageLogin};


function ioLogout() {
	socket.emit('isLogout');
}
export {ioLogout};



function ioFormLogin(l) {
	socket.on('ioFormLogin', ioFormLogin => l(null, ioFormLogin));
}
export {ioFormLogin};

function Off_ioFormLogin() {
	socket.off('ioFormLogin');
}
export {Off_ioFormLogin};



function messagesInfo(d) {
	socket.on('message', mes => d(null, mes));
}
export {messagesInfo};

function Off_messagesInfo() {
	socket.off('message');
}
export {Off_messagesInfo};




function BookmarkSearch(value) {
	socket.emit('bookmark_search', value, '');
}
export {BookmarkSearch};

function BookmarkSearchLentaValue(color) {
	socket.emit('bookmark_search', '', color);
}
export {BookmarkSearchLentaValue};


function BookmarkSearchValue(value) {
	socket.on('search_value', search_value => value(null, search_value));
}
export {BookmarkSearchValue};

function Off_BookmarkSearchValue() {
	socket.off('search_value');
}
export {Off_BookmarkSearchValue};



function Categories(d) {
	socket.emit('categories');
	socket.on('categories', categories => d(null, categories));
}
export {Categories};

function Off_Categories() {
	socket.off('categories');
}
export {Off_Categories};


function CategoriesPlus(limit) {
	socket.emit('categories', limit);
}
export {CategoriesPlus};


function CatClick(name) {
	socket.emit('cat_click_update', name);
}
export {CatClick};


function TileClick(id, cat) {
	socket.emit('tile_click', id, cat);
}
export {TileClick};


function TileNullClick(id) {
	socket.emit('tile_null_click', id);
}
export {TileNullClick};


function TileSort(sort) {
	socket.emit('tile_sort', sort);
}
export {TileSort};


function SettingsClose() {
	socket.emit('settingsClose');
}
export {SettingsClose};



function SettingsUpdate(name, value) {
	socket.emit('settings_edit', name, value);
}
export {SettingsUpdate};

function SettingsEdit() {
	socket.emit('settings');
}
export {SettingsEdit};

function SettingsEditUser(id) {
	socket.on('settings', settings => id(null, settings));
}
export {SettingsEditUser};

function Off_SettingsEditUser() {
	socket.off('settings');
}
export {Off_SettingsEditUser};


function Timer(send) {
	socket.emit('timer_day', send);
}
export {Timer};

function TimerOn(d) {
	socket.on('timer_day', day => d(null, day));
}
export {TimerOn};

function Off_Timer() {
	socket.off('timer_day');
}
export {Off_Timer};


function BookmarkEdit(id) {
	socket.on('bookmark', bookmark => id(null, bookmark));
}
export {BookmarkEdit};

function Off_BookmarkEdit() {
	socket.off('bookmark');
}
export {Off_BookmarkEdit};


function BookmarkNoEdit(id) {
	socket.on('bookmark_no_edit', bookmark_no_edit => id(null, bookmark_no_edit));
}
export {BookmarkNoEdit};

function Off_BookmarkNoEdit() {
	socket.off('bookmark_no_edit');
}
export {Off_BookmarkNoEdit};


function BookmarkEditEmit(id) {
	socket.emit('bookmark', id);
}
export {BookmarkEditEmit};

function BookmarkEditClose(id) {
	socket.emit('bookmarkClose', id);
}
export {BookmarkEditClose};

function BookmarkEditID(id, name, value) {
	socket.emit('bookmark_edit', id, name, value);
}
export {BookmarkEditID};

function BookmarkBook(id) {
	socket.emit('bookmark_book', id);
}
export {BookmarkBook};

function BookmarkReturnBook(id) {
	socket.emit('bookmark_return_book', id);
}
export {BookmarkReturnBook};

function BookmarkBasket(id) {
	socket.emit('bookmark_basket', id);
}
export {BookmarkBasket};

function BookmarkSearchLenta(l) {
	socket.on('bookmark_lenta', lenta => l(null, lenta));
}
export {BookmarkSearchLenta};

function Off_BookmarkSearchLenta() {
	socket.off('bookmark_lenta');
}
export {Off_BookmarkSearchLenta};


function TileUp(u) {
	socket.on('tile_up', tile_up => u(null, tile_up));
}
export {TileUp};

function Off_TileUp() {
	socket.off('tile_up');
}
export {Off_TileUp};


function ioNewToken(token) {
	socket.emit('new_token', token);
	socket.on('new_token', token => token(null, token));
}
export {ioNewToken};

function Off_ioNewToken() {
	socket.off('new_token');
}
export {Off_ioNewToken};



//   IP
function yoIp(d) {
	socket.on('x-real-ip', ip => d(null, ip));
}
export {yoIp};

function Off_yoIp() {
	socket.off('x-real-ip');
}
export {Off_yoIp};


function yoIp2(d) {
	socket.on('x-forwarded-for', ip => d(null, ip));
}
export {yoIp2};

function Off_yoIp2() {
	socket.off('x-forwarded-for');
}
export {Off_yoIp2};

class SocketOne {
	constructor(options) {
		this.name = options.name
		this.save = options.save
	}
	emit(send){
		console.log(send)
		socket.emit(this.name, send);
	}
	on(s){
		// eslint-disable-next-line react-hooks/rules-of-hooks
		//const dispatch = useDispatch()
		//socket.on(this.name, data => dispatch(this.save(data)))
		socket.on(this.name, data => s(null, data))
	}
	off(){
		socket.off(this.name)
	}
}

function UserAgent(d) {
	socket.on('user-agent', a => d(null, a));
}
export {UserAgent};

function Off_UserAgent() {
	socket.off('user-agent');
}
export {Off_UserAgent};


function Info(d) {
	socket.on('info', info => d(null, info));
}
export {Info};

function Off_Info() {
	socket.off('info');
}
export {Off_Info};


function GeoLite2Inquiry(ip) {
	socket.emit('geo-lite2', ip);
}
export {GeoLite2Inquiry};

function GeoLite2(ip) {
	socket.on('geo-lite2', geo => ip(null, geo));
}
export {GeoLite2};

function Off_GeoLite2() {
	socket.off('geo-lite2');
}
export {Off_GeoLite2};


function GeoLite2Org(ip) {
	socket.emit('geo-organization', ip);
}
export {GeoLite2Org};

function GeoLite2Organization(or) {
	socket.on('geo-organization', org => or(null, org));
}
export {GeoLite2Organization};

function Off_GeoLite2Organization() {
	socket.off('geo-organization');
}
export {Off_GeoLite2Organization};


// Погода
function PogodaEmit(send) {
	socket.emit('pogoda', send);
}
export {PogodaEmit};

function Pogoda(c) {
	socket.on('pogoda', pogoda => c(null, pogoda));
}
export {Pogoda};

function Off_Pogoda() {
	socket.off('pogoda');
}
export {Off_Pogoda};


// Погода - my ip
function PogodaAPI(res) {
	socket.on('pogoda_api', response => res(null, response));
}
export {PogodaAPI};

function Off_PogodaAPI() {
	socket.off('pogoda_api');
}
export {Off_PogodaAPI};


// ТВ
function tvShow(t) {
	socket.on('tv_show', show => t(null, show));
}
export {tvShow};

function Off_tvShow() {
	socket.off('tv_show');
}
export {Off_tvShow};

// ТВ Программа
function tvProgramme(t) {
	socket.on('tv_programme', broadcast => t(null, broadcast));
}
export {tvProgramme};

function Off_tvProgramme() {
	socket.off('tv_programme');
}
export {Off_tvProgramme};


// STOP SPAM
function SpamEmit(action, code) {
	socket.emit('spam', action, code);
}
export {SpamEmit};

function SpamOn(s) {
	socket.on('spam', spam => s(null, spam));
}
export {SpamOn};

function Off_SpamOn() {
	socket.off('spam');
}
export {Off_SpamOn};


//  ADMIN
function InfoAdminA(i) {
	socket.on('admin_info_a', info => i(null, info));
}
export {InfoAdminA};

function Off_InfoAdminA() {
	socket.off('admin_info_a');
}
export {Off_InfoAdminA};

function InfoAdminB(i) {
	socket.on('admin_info_b', info => i(null, info));
}
export {InfoAdminB};

function Off_InfoAdminB() {
	socket.off('admin_info_b');
}
export {Off_InfoAdminB};

function InfoAdminC(i) {
	socket.on('admin_info_c', info => i(null, info));
}
export {InfoAdminC};

function Off_InfoAdminC() {
	socket.off('admin_info_c');
}
export {Off_InfoAdminC};

function InfoAdminD(i) {
	socket.on('admin_info_d', info => i(null, info));
}
export {InfoAdminD};

function Off_InfoAdminD() {
	socket.off('admin_info_d');
}
export {Off_InfoAdminD};

function InfoAdminI(i) {
	socket.on('admin_info_i', info => i(null, info));
}
export {InfoAdminI};

function Off_InfoAdminI() {
	socket.off('admin_info_i');
}
export {Off_InfoAdminI};

function InfoAdminF(status) {
	socket.on('admin_info_f', info => status(null, info));
}
export {InfoAdminF};

function Off_InfoAdminF() {
	socket.off('admin_info_f');
}
export {Off_InfoAdminF};

function InfoAdminFEmit(status) {
	socket.emit('admin_info_f_emit', status);
}
export {InfoAdminFEmit};


// Проверка подключение к сокету
function socketConnected() {
	return socket.connected;
}
export {socketConnected};

function Ready() {
	socket.emit('ready');
}
export {Ready};

function Off_Ready() {
	socket.off('ready');
}
export {Off_Ready};

// clipboard
function clipboardOpen(b) {
	socket.on('clipboard-open', item => b(null, item));
}
export {clipboardOpen};

function Off_clipboardOpen() {
	socket.off('clipboard-open');
}
export {Off_clipboardOpen};


 */

/*
function ClipboardEmit(text) {
	socket.emit('clipboard', text);
}
export {ClipboardEmit};

function ClipboardOn(t) {
	socket.on('clipboard', text => t(null, text));
}
export {ClipboardOn};

function Off_ClipboardOn() {
	socket.off('clipboard');
}
export {Off_ClipboardOn};
*/