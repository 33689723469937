import { Helmet } from 'react-helmet'

import { Link } from 'react-router-dom'

import './index.scss'

function Error() {

	return (<>
		<Helmet>
			<title>BF5.RU - Error 404</title>
			<meta name="description" content="Error 404" />
		</Helmet>

		<div className='page-error'>
			<div>
				<h1>Error 404</h1>
				<Link to='/'>На главную</Link>
			</div>
		</div>
	</>)
}

export default Error