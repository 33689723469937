import { createSlice } from '@reduxjs/toolkit'

export const Socket = createSlice({
	name: "socket",
	initialState: {
		connect: null, // Счётчик подключений
		message: null, // Сообщения с сервера (Component <Message />)
		timer: 5000, // Время показа сообщения
		users: null, // Количество пользователей на сайте
	},
	reducers: {
		setConnect: (state, status) => {
			state.connect = status.payload
		},
		setMessage: (state, status) => {
			state.message = status.payload.message || null
			state.timer = status.payload.timer || 10000
		},
		setUsers: (state, users) => {
			state.users = JSON.parse(users.payload)
		}
	}
})

export const {
	setConnect,
	setMessage,
	setUsers
} = Socket.actions

export const valConnect = (state) => state.socket.connect
export const valMessage = (state) => state.socket.message
export const valTimer = (state) => state.socket.timer
export const allUsers = (state) => state.socket.users

export default Socket.reducer


