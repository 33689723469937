import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { declination } from '../../config'
import { userIP } from '../../store/authorization'

import './index.scss'

function NoConnect(props) {
	const ip = useSelector(userIP)
	const { connect } = props

	return (<>
		<Helmet>
			<title>BF5.RU - Нет соединения с сервером!</title>
			<meta name="description" content="Нет соединения с сервером!" />
		</Helmet>

		<div className='no-connect'>
			<div>
				<h1>Нет соединения с сервером!</h1>
				<span>{connect} {declination(connect, ['секунд', 'секунда', 'секунды'])}</span>
				<span>{ip}</span>
			</div>
		</div>
	</>)
}

export default NoConnect