//import { useEffect } from 'react'

import UiInfoCalendar from '../../../ui/info-calendar'

import './index.scss'

function InfoCalendar(props) {
	const { turnOn } = props

	if(!turnOn) return null

	return (<div className='info-calendar'>
		<div>
			<div>

				<UiInfoCalendar />

			</div>
		</div>
	</div>)
}

export default InfoCalendar